import { RiArrowDownSLine, RiArrowUpSLine, RiDashboardFill, RiHistoryFill, RiSettings5Line } from 'react-icons/ri';
import { GoDashboard } from 'react-icons/go';
import { ImEye, ImEyePlus, ImUserPlus } from 'react-icons/im';
import { CgSearchFound } from 'react-icons/cg';
import { RiAccountPinBoxFill } from "react-icons/ri";
import { GiWarPick, GiIceCube, GiHorseshoe, GiCartwheel } from 'react-icons/gi';
import { FaRegSnowflake, FaCrown } from 'react-icons/fa';

export const mainbarData = [
    {
        title: 'dashboard',
        path: '/dashboard',
        icon: <RiDashboardFill />,
    },
    {
        title: 'advertise',
        icon: <ImEyePlus />,
        iconClosed: <RiArrowDownSLine />,
        iconOpened: <RiArrowUpSLine />,
        subNav: [
            {
                title: 'set-up ads',
                path: '/setup-ads',
            },
            {
                title: 'control ads',
                path: '/control-ads',
            },
        ],
    },
    {
	title: 'daily bonus',
	path: '/lucky-wheel',
	icon: <GiCartwheel />,
    },
    {
        title: 'faucet',
        path: '/faucet',
        icon: <GoDashboard />,
    },
    {
	title: 'offer',
	path: '/offers',
	icon: <FaCrown />,
    },
    {
        title: 'view ads',
        icon: <ImEye />,
        iconClosed: <RiArrowDownSLine />,
        iconOpened: <RiArrowUpSLine />,
        subNav: [
	    {
		title: 'shortlink',
		path: '/shortlink',
	    },
            {
                title: 'ptc',
                path: '/ptc/1',
            },
            {
                title: 'surf',
                path: '/surf/1',
            },
            {
                title: 'video',
                path: '/video',
            },
	    {
		title: 'auto',
		path: '/auto',
	    },
        ],
    },
    {
	title: 'task',
	path: '/task',
	icon: <FaRegSnowflake />,
    },
    {
	title: 'social marketing',
	path: '/social-marketing',
	icon: <RiAccountPinBoxFill />,
    },
    {
	title: 'lottery',
	path: '/lottery',
	icon: <GiHorseshoe />,
    },
    {
        title: 'affiliate',
        icon: <ImUserPlus />,
        iconClosed: <RiArrowDownSLine />,
        iconOpened: <RiArrowUpSLine />,
        subNav: [
            {
                title: 'promotion materials',
                path: '/promotion-materials',
            },
            {
                title: 'manage referrals',
                path: '/manage-referrals/1',
            },
        ],
    },
    {
        title: 'mining icecoin',
        icon: <GiWarPick />,
        iconClosed: <RiArrowDownSLine />,
        iconOpened: <RiArrowUpSLine />,
        subNav: [
            {
		title: 'card mining',
		path: '/card-mining',
	    }
        ],
    },
    {
        title: 'stake',
        path: '/stake',
        icon: <GiIceCube />,
    },
    {
        title: 'statistics',
        icon: <CgSearchFound />,
        iconClosed: <RiArrowDownSLine />,
        iconOpened: <RiArrowUpSLine />,
        subNav: [
            {
                title: 'activities',
                path: '/activities',
            },
            {
                title: 'referrals activities',
                path: '/referrals-activities',
            },
        ],
    },
    {
        title: 'history panel',
        path: '/history-panel',
        icon: <RiHistoryFill />,
    },
    {
        title: 'settings',
        path: '/settings',
        icon: <RiSettings5Line />,
    },
];
