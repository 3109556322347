import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import { MotherContainer } from '../components/PtcDetail/PtcDetailElements';
import IcefaCaptcha from '../components/Captcha/IcefaCaptcha';

const SLDonePage = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [captchaDone, setCaptchaDone] = useState(false);
    const [complete, setComplete] = useState('');
    const dark = localStorage.getItem('lightness') ? true : false;
    useEffect(() => {
	const fetchShortLinkDone = async () => {
	    setLoading(true);
	    try {
		await axiosInstance.get(`shortlink/complete/${match.params.code}/${match.params.token}/`);
		setTimeout(() => window.open(`https://icefaucet.com/shortlink`, '_self', 'noreferrer'), 1000);
		setComplete(true);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	    setLoading(false);
	};
	captchaDone && fetchShortLinkDone();
    }, [match.params.code, match.params.token, captchaDone]);
    return (
	<MotherContainer dark={dark ? 1 : 0}>
	    {!captchaDone &&
		<IcefaCaptcha appName='sl' setCaptchaDone={setCaptchaDone} dark={0} />
	    }
	    {loading &&
		<div style={{ display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: '30px auto', width: '30px', height: '30px' }} />
		</div>
	    }
	    {!loading && !error && complete &&
		<MsgWrap primary={1} style={{ width: '80%', margin: 'auto', position: 'relative', top: '15px'  }}>
		    <MsgText primary={1}>
			You have successfully earned.
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && error &&
		<MsgWrap primary={0} style={{ width: '80%', margin: 'auto', position: 'relative', top: '15px' }}>
		    <MsgText primary={0}>
			{error}
		    </MsgText>
		</MsgWrap>
	    }
	</MotherContainer>
    )
}
export default SLDonePage;
