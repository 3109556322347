import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../components/TOS/TOSElements';
import { BigContainer, ContainerLogout, WrapperLogout, ContentContainer, ContentWrap } from '../components/Auth/LogoutElements';
import logo from '../images/logo.png';
import { TinyButton } from '../components/ButtonElements';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';

const ChangeStatusPayeer = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const m_operation_id = queryParameters.get('m_operation_id');
    const m_operation_ps = queryParameters.get('m_operation_ps');
    const m_operation_date = queryParameters.get('m_operation_date');
    const m_operation_pay_date = queryParameters.get('m_operation_pay_date');
    const m_shop = queryParameters.get('m_shop');
    const m_orderid = queryParameters.get('m_orderid');
    const m_amount = queryParameters.get('m_amount');
    const m_curr = queryParameters.get('m_curr');
    const m_desc = queryParameters.get('m_desc');
    const m_status = queryParameters.get('m_status');
    const m_sign = queryParameters.get('m_sign');
    useEffect(() => {
	const fetchPayeerCallback = async () => {
	    try {
		await axiosInstance.get('financial/cash-in/payeer/callback/', {
		    params: {
			'm_operation_id': m_operation_id,
			'm_operation_ps': m_operation_ps,
			'm_operation_date': m_operation_date,
			'm_operation_pay_date': m_operation_pay_date,
			'm_shop': m_shop,
			'm_orderid': m_orderid,
			'm_amount': m_amount,
			'm_curr': m_curr,
			'm_desc': m_desc,
			'm_status': m_status,
			'm_sign': m_sign
		    }
		});
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	    setLoading(false);
	};
	fetchPayeerCallback();
    }, [m_operation_id, m_operation_ps, m_operation_date, m_operation_pay_date, m_shop, m_orderid, m_amount, m_curr, m_desc, m_status, m_sign]);
    return (
	<BigContainer>
	    <ContainerLogout>
	        <WrapperLogout>
		    <Header style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
		        <LeftWrap>
		            <LogoWrapLink to='/'>
		                <img src={logo} alt={'logo of icefaucet'} />
		            </LogoWrapLink>
		        </LeftWrap>
		        <SubjectWrap>
		            <h1>deposit</h1>
		        </SubjectWrap>
		    </Header>
		    <ContentContainer>
		        <ContentWrap>
		            {loading && !error &&
				<>
				    <span><Circle /></span>
				    <p style={{ color: '#01DBBB' }}>Please wait...</p>
				</>
			    }
		            {!loading && !error &&
				<p style={{ color: '#01DBBB' }}>Your deposit status changed to "completed" and your account was charged. Thanks for choosing our service.</p>
			    }
		            {!loading && error &&
				<MsgWrap primary={0} style={{ width: '100%', margin: 'auto' }}>
				    <MsgText primary={0}>
					{error}
				    </MsgText>
				</MsgWrap>
			    }
		        </ContentWrap>
		        {!loading && !error &&
			    <>
				<TinyButton to='/help' primary={1} big={0} style={{ width: '250px', margin: 'auto' }}>Support ticket</TinyButton>
				<TinyButton to='/history-panel' primary={1} big={0} style={{ width: '250px', margin: '10px auto' }}>your history payments</TinyButton>
			    </>
			}
		    </ContentContainer>
		</WrapperLogout>
	    </ContainerLogout>
	</BigContainer>
    )
}
export default ChangeStatusPayeer;
