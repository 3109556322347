import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import ViewAdsControl from '../components/AdsControl/ViewAdsControl';
import AutoAdsControl from '../components/AdsControl/AutoAdsControl';
import BannerAdsControl from '../components/AdsControl/BannerAdsControl';
import SponsorAdsControl from '../components/AdsControl/SponsorAdsControl';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { StackContainer, StackWrap, TitleWrap } from '../components/Stack/StackElements';
import { HelpIcon, HelpWrap } from '../components/HelpElements';
import { Circle } from '../components/Loading';
import { IconWrap, DownIcon, UpIcon } from '../components/HelpDesk/FAQElements';
import { adToolsControl } from '../data/controlAdsData';

const ControlAdsPage = () => {
    const [infoPTC, setInfoPTC] = useState();
    const [adscoin, setAdscoin] = useState();
    const [infoSurf, setInfoSurf] = useState();
    const [infoVideo, setInfoVideo] = useState();
    const [infoAuto, setInfoAuto] = useState();
    const [infoBanner, setInfoBanner] = useState();
    const [infoSponsor, setInfoSponsor] = useState();
    const [error, setError] = useState({
	'your PTC ads': '',
	'your surf ads': '',
	'your video ads': '',
	'your auto ads': '',
	'your banner': '',
	'your sponsor link': '',
    });
    const [arrowClick, setArrowClick] = useState({
	'your PTC ads': true,
	'your surf ads': false,
	'your video ads': false,
	'your auto ads': false,
	'your banner': false,
	'your sponsor link': false,
    });
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [click, setClick] = useState('your PTC ads');
    const [loading2, setLoading2] = useState(false);
    const handleClick = (tool) => {
	setClick(tool);
	setArrowClick({ ...arrowClick, [tool]: !arrowClick[tool] });
	!loading2 && tool === 'your surf ads' && infoSurf === undefined && !error[tool] && fetchControlAdsInfo(tool, 8);
	!loading2 && tool === 'your video ads' && infoVideo === undefined && !error[tool] && fetchControlAdsInfo(tool, 6);
	!loading2 && tool === 'your auto ads' && infoAuto === undefined && !error[tool] && fetchControlAdsInfo(tool, 4);
	!loading2 && tool === 'your banner' && infoBanner === undefined && !error[tool] && fetchControlBannerAdsInfo(tool);
	!loading2 && tool === 'your sponsor link' && infoSponsor === undefined && !error[tool] && fetchControlSponsorAdsInfo(tool);
    };
    const fetchControlAdsInfo = async (tool, type) => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.post('ptc/control-ads/view-ads/', { 'type': type });
	    type === 8 && setInfoSurf(data.info);
	    type === 6 && setInfoVideo(data.info);
	    type === 4 && setInfoAuto(data.info);
	    setAdscoin(data.user_adscoin);
	} catch (error) {
	    error.response && error.response.data.detail ? setError({ ...error, [tool]: error.response.data.detail }) : setError({ ...error, [tool]: error.message });
	};
	setLoading2(false);
    };
    const fetchControlBannerAdsInfo = async (tool) => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.get('banner/control-ads/banner/');
	    setInfoBanner(data.info);
	} catch (error) {
	    error.response && error.response.data.detail ? setError({ ...error, [tool]: error.response.data.detail }) : setError({ ...error, [tool]: error.message });
	};
	setLoading2(false);
    };
    const fetchControlSponsorAdsInfo = async (tool) => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.get('sponsor/control-ads/sponsor/');
	    setInfoSponsor(data.info);
	} catch (error) {
	    error.response && error.response.data.detail ? setError({ ...error, [tool]: error.response.data.detail }) : setError({ ...error, [tool]: error.message });
	};
	setLoading2(false);
    };
    useEffect(() => {
	const fetchControlPTCAdsInfo = async () => {
	    try {
		const { data } = await axiosInstance.post('ptc/control-ads/view-ads/', { 'type': 10 });
		setInfoPTC(data.info);
		setAdscoin(data.user_adscoin);
	    } catch (error) {
		error.response && error.response.data.detail ? setError({ ...error, 'your PTC ads': error.response.data.detail }) : setError({ ...error, 'your PTC ads': error.message });
		error.response && error.response.data.detail && setAdscoin(error.response.data.user_adscoin);
	    };
	    setLoading(false);
	};
	fetchControlPTCAdsInfo();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading &&
		<>
		    {adToolsControl.map((tool, index) => (
			<StackContainer primary={1} key={index}>
			    <StackWrap primary={1}>
			        <TitleWrap primary={1} style={{ justifyContent: 'space-between', padding: '0 3%', cursor: 'pointer' }} onClick={() => handleClick(tool)}>
			            <div style={{ display: 'flex' }}>
			                <h2>{tool}</h2>
			                {(tool === 'your PTC ads' || tool === 'your surf ads' || tool === 'your video ads') && <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp && click === tool ? 1 : 0} />}
			            </div>
			            <IconWrap>
			                {arrowClick[tool] ? <UpIcon /> : <DownIcon />}
			            </IconWrap>
			        </TitleWrap>
			        <HelpWrap show={showHelp && click === tool ? 1 : 0}>
			            <p>
			                To edit or delete your ads on watching status,
			                you should stop your ads first and wait until the watchers of ads become zero.
			            </p>
			        </HelpWrap>
			        {error[tool] && arrowClick[tool] &&
				    <MsgWrap primary={0}>
					<MsgText primary={0}>
					    {error[tool]}
					</MsgText>
				    </MsgWrap>
				}
			        {infoPTC !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your PTC ads' &&
				    <ViewAdsControl info={infoPTC} adscoin={adscoin} />
				}
			        {infoSurf !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your surf ads' &&
				    <ViewAdsControl info={infoSurf} adscoin={adscoin} />
				}
			        {infoVideo !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your video ads' &&
				    <ViewAdsControl info={infoVideo} adscoin={adscoin} />
				}
			        {infoAuto !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your auto ads' &&
				    <AutoAdsControl info={infoAuto} adscoin={adscoin} />
				}
			        {infoBanner !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your banner' &&
				    <BannerAdsControl info={infoBanner} adscoin={adscoin} />
				}
			        {infoSponsor !== undefined && arrowClick[tool] && adscoin !== undefined && !error[tool] && tool === 'your sponsor link' &&
				    <SponsorAdsControl info={infoSponsor} adscoin={adscoin} />
				}
			        {loading2 && click === tool &&
				    <div style={{ height: '20vh', display: 'flex', alignItems: 'center' }}>
					<Circle style={{ margin: 'auto', width: '40px', height: '40px' }} />
				    </div>
				}
			    </StackWrap>
			</StackContainer>
		    ))}
		</>
	    }
	</>
    )
}
export default ControlAdsPage;
