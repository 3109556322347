import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap, RentContainer, RentForm, RentInstruction, ButtonWrap, InputContainer } from './StackElements';
import { AICard, AIImageWrap, AIImage, AIContext, AIContextItem } from '../AccountInfo/AccountInfoElements';
import { stackTimeData } from '../../data/stackData';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import one from '../../images/level-1.png';
import two from '../../images/level-2.png';
import three from '../../images/level-3.png';
import four from '../../images/level-4.png';
import five from '../../images/level-5.png';
import master from '../../images/level-master.png';

const RentRefrigrators = ({ info }) => {
    const [time, setTime] = useState(Object.freeze(''));
    const [timeIndex, setTimeIndex] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [complete, setComplete] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        !time && setError('You should select time of working refrigerators first.');
        setIsSubmitting(true);
    };
    const handleSelect = (e, index) => {
        let time = e.target.getAttribute('value');
        setTime(parseInt(time));
        setTimeIndex(index);
        setError('');
        setIsSubmitting(false);
    };
    const fetchRentRefrigrators = async () => {
        try {
            const { data } = await axiosInstance.post('interest/rent/', {
                'time': time
            });
            if (data.rent) {
                setComplete(true);
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            };

        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (isSubmitting && !complete && error.length === 0) {
            fetchRentRefrigrators();
        };
    });
    useEffect(() => {
        if (info.icecoin < 10000) {
            setError('You should have at least 10000 icecoin to rent a refrigerator.')
        }
    }, [info]);
    return (
        <StackContainer primary={0}>
            <StackWrap primary={0}>
                <TitleWrap primary={0}>
                    <h2>rent refrigerators</h2>
                </TitleWrap>
                <StackDiscWrap>
                    <p>
                        You should freeze 10000 icecoins to rent every refrigerator. The freezing process needs 3 days,
                        and after that, your refrigerator starts to work. After your refrigerator makes your profit, your refrigerator will be expired and
                        your icecoins will be free to withdraw. Your investment is used in iceFaucet company and the cryptocurrency industry.
                    </p>
                </StackDiscWrap>
                <RentContainer>
                    <AICard style={{ height: '250px' }}>
                        <AIImageWrap style={{ width: '100px', height: '100px' }}>
                            <AIImage src={
                                info.info.level === 6
                                    ? master
                                    : info.info.level === 2
                                        ? two
                                        : info.info.level === 3
                                            ? three
                                            : info.info.level === 4
                                                ? four
                                                : info.info.level === 5
                                                    ? five
                                                    : one
                            }
                                alt={'stack level image'} title={`your level: ${info.info.level}`} />
                        </AIImageWrap>
                        <AIContext>
                            <AIContextItem>
                                <span>annual profit:</span>
                                <span>
                                    +{info.info.annual_profit}%
                                </span>
                            </AIContextItem>
                            <AIContextItem>
                                <span>upcode:</span>
                                <span>
                                    +{info.info.upcode}%
                                </span>
                            </AIContextItem>
                            <AIContextItem>
                                <span>refrigerators:</span>
                                <span>{info.info.number_refrigerator}</span>
                            </AIContextItem>
                        </AIContext>
                    </AICard>
                    <RentForm action='#' onSubmit={handleSubmit}>
                        <RentInstruction>
                            <p>
                                Select the time of the working refrigerator:
                            </p>
                        </RentInstruction>
                        <InputContainer>
                            <ButtonWrap>
                                {stackTimeData.map((item, index) => (
                                    <TinyFormButton
                                        key={index}
                                        type='button'
                                        value={item.value}
                                        primary={timeIndex === index ? 1 : 0}
                                        big={0}
                                        style={{ width: '100px' }}
                                        onClick={(e) => handleSelect(e, index)}
                                    >
                                        {item.name}
                                    </TinyFormButton>
                                ))}
                            </ButtonWrap>
                        </InputContainer>
                        {!complete && !error &&
                            <TinyFormButton type='submit' primary={isSubmitting ? 1 : 0} big={0} style={{ margin: '5px 0' }}>
                                rent
                            </TinyFormButton>
                        }
                        {error &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                        {complete &&
                            <MsgWrap primary={1}>
                                <MsgText primary={1}>
                                    You rent a refrigerator successfully.
                                </MsgText>
                            </MsgWrap>
                        }
                    </RentForm>
                </RentContainer>
            </StackWrap>
        </StackContainer>
    )
}
export default RentRefrigrators
