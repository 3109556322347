import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { BannerFaucetWrap, DBigContainerFaucet, DLeftContainerFaucet, DRightContainerFaucet } from '../DesktopBanner/DesktopBannerElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FaucetTimer } from '../Mainbar/MainbarElements';
import { MsgText, MsgWrap } from '../MessageElements';
import LoadingContest from '../Contest/LoadingContest';
import LoadingContestOffer from '../ContestOffer/LoadingContest';
import WheelComponentPromo from '../LuckyWheel/SpinPromo';
import LotteryPromo from '../Lottery/LotteryPromo';

const FaucetDeactiveDesktopBanners = ({ timer }) => {
    const [fetchBanner, setFetchBanner] = useState(false);
    const renderer = ({ minutes, seconds }) => {
	return <FaucetTimer>{zeroPad(minutes)}:{zeroPad(seconds)}</FaucetTimer>;
    };
    const [desktopBanner, setDesktopBanner] = useState();
    useEffect(() => {
	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('src', 'https://appsha-pnd.ctengine.io/js/script.js?wkey=T5aQuSkaCC');
	document.body.appendChild(script);
	return new Promise(() => {
	    script.remove();
	});
    }, []);
    useEffect(() => {
	const fetchDesktopBanner = async () => {
	    const { data } = await axiosInstance.get('banner/deactive/faucet/desktop/');
	    setDesktopBanner(data);
	    setFetchBanner(true);
	    if (data.p2 !== null) {
	        const ins = document.getElementById('surf1');
	        ins.className = 'surfe-be';
	        ins.setAttribute('data-sid', data.p2.data);
	        const script = document.createElement('script');
	        script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	        document.body.appendChild(script);
	        return new Promise(() => {
		    script.remove();
	        });
	    };
	};
	fetchDesktopBanner();
    }, []);
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<>
	    <DBigContainerFaucet>
	        <DLeftContainerFaucet>
	    	    {fetchBanner && timer !== undefined &&
			<div style={{ width: '100%', margin: '5px auto' }}>
			    <MsgWrap primary={0}>
			        <MsgText primary={0}>
			            <Countdown
			                date={Date.now() + timer * 1000}
			                renderer={renderer}
			            />
			        </MsgText>
			    </MsgWrap>
			</div>
		    }
	    	    {fetchBanner &&
			<LoadingContestOffer big={0} table={false} />
		    }
	    	    {desktopBanner !== undefined && desktopBanner.p2 !== null &&
			<div style={{ margin: '15px auto 0 auto' }}>
			    <ins id='surf1'></ins>
			</div>
		    }
	    	    {fetchBanner &&
			<WheelComponentPromo
			    segments={["LOTTERY", "THUNDER", "250 PC", "350 PC", "500 PC", "ICEDAY", "1000 PC", "2500 PC"]}
			    segColors={[
				"rgba(29, 107, 187, 0.5)",
				"rgba(39, 117, 85, 0.5)",
				"rgba(120, 116, 16, 0.5)",
				"rgba(90, 20, 200, 0.5)",
				"rgba(29, 107, 187, 0.85)",
				"rgba(39, 117, 85, 0.85)",
				"rgba(120, 116, 16, 0.85)",
				"rgba(90, 20, 200, 0.85)",
			    ]}
			    primaryColor="rgba(1, 219, 187, 1)"
			    contrastColor="rgba(29, 107, 187, 1)"
			    size={100}
			    upDuration={200}
			    downDuration={1100}
			/>
		    }
	    	    {fetchBanner &&
			<LoadingContest big={0} table={false} />
		    }
	    	    {fetchBanner && <LotteryPromo big={1} />}
	    	</DLeftContainerFaucet>
	        <DRightContainerFaucet>
	    	    {desktopBanner !== undefined && desktopBanner.p1 !== null && desktopBanner.plan === 'A' &&
			<div style={{ margin: '15px auto' }}>
			    <BannerFaucetWrap href={desktopBanner.p1.url} target='_blank' onClick={() => handleBannerClicked(desktopBanner.p1.banner_id)} onContextMenu={handleRightClick}>
			        <img src={desktopBanner.p1.banner_img ? desktopBanner.p1.banner_img : desktopBanner.p1.banner_url ? desktopBanner.p1.banner_url : null} alt={desktopBanner.p1.title} title={desktopBanner.p1.url.split('/')[2]} />
			    </BannerFaucetWrap>
			</div>
		    }
	    	    {desktopBanner !== undefined && desktopBanner.p1 !== null && desktopBanner.plan === 'B' &&
			<div style={{ margin: '15px auto' }}>
			    <iframe title='cryptocoinsad'
			        src={desktopBanner.p1.src}
			        style={{ width: `${desktopBanner.p1.width}px`, height: `${desktopBanner.p1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			    </iframe>
			</div>
		    }
	    	    {desktopBanner !== undefined && desktopBanner.p3 !== null &&
			<div style={{ margin: '0 auto' }}>
			    <iframe title='faucetpay'
			        id={desktopBanner.p3.banner_id}
			        src={desktopBanner.p3.src}
			        width={desktopBanner.p3.width}
			        height={desktopBanner.p3.height}
			        style={{ width: `${desktopBanner.p3.width}px`, height: `${desktopBanner.p3.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			    </iframe>
			</div>
		    }
	    	</DRightContainerFaucet>
	    </DBigContainerFaucet>
	    {desktopBanner !== undefined && desktopBanner.p4 !== null && desktopBanner.plan === 'A' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto' }}>
		    <BannerFaucetWrap href={desktopBanner.p4.url} target='_blank' onClick={() => handleBannerClicked(desktopBanner.p4.banner_id)} onContextMenu={handleRightClick}>
		        <img src={desktopBanner.p4.banner_img ? desktopBanner.p4.banner_img : desktopBanner.p4.banner_url ? desktopBanner.p4.banner_url : null} alt={desktopBanner.p4.title} title={desktopBanner.p1.url.split('/')[2]} />
		    </BannerFaucetWrap>
		</div>
	    }
	    {desktopBanner !== undefined && desktopBanner.p4 !== null && desktopBanner.plan === 'B' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={desktopBanner.p4.src}
		        style={{ width: `${desktopBanner.p4.width}px`, height: `${desktopBanner.p4.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	</>
    )
}
export default FaucetDeactiveDesktopBanners;
