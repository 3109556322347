import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import ScrollToTop from '../components/ScrollToTop';
import HeroHomeSection from '../components/HeroHomeSection';
import InfoSection from '../components/InfoSection';
import { heroHomeData, langHeroHomeData } from '../data/heroSectionData';
import { homeObjOne, langHomeObjOne, homeObjTwo, langHomeObjTwo, homeObjThree, langHomeObjThree } from '../data/infoSectionData';
import { featureHead, langFeatureHead, homeFeatures, langHomeFeatures } from '../data/featuresData';
import Features from '../components/Features';
import IceStory from '../components/IceStory';
import { StoryOutContainer, StoryContainer, StoryHeroTitleWrap, ActiveStoryContainer, ActiveStoryList, ActiveStoryImg } from '../components/IceStory/IceStoryElements';

const HomePage = () => {
    const pageLang = localStorage.getItem('pageLang');
    const [device, setDevice] = useState();
    const [cryptoCoinsAdInfo, setCryptoCoinsAdInfo] = useState();
    const [surfInfo, setSurfInfo] = useState();
    const [FPInfo, setFPInfo] = useState();
    const [data, setData] = useState();
    const [storyInfo, setStoryInfo] = useState();
    const [activeStory, setActiveStory] = useState(0);
    const [stop, setStop] = useState(false);
    const [finishStory, setFinishStory] = useState(false);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 880) {
	    setDevice('M');
	} else {
	    setDevice('D');
	};
    };
    useEffect(() => {
	const fetchHomeInfo = async () => {
	    try {
		const { data } = await axios.get('https://icefaucet.com/api/home/');
		setData(data);
		fetchStoryInfo();
	    } catch (error) {
	    };
	};
	fetchHomeInfo();
    }, []);
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    const fetchStoryInfo = async () => {
	const { data } = await axios.get('https://icefaucet.com/api/story/view/out/');
	setStoryInfo(data.info);
    };
    useEffect(() => {
    	if (storyInfo !== undefined && Object.keys(storyInfo).length > 0 && !stop && !finishStory) {
	    if (activeStory === Object.keys(storyInfo).length) {
		setFinishStory(true);
		return;
	    };
	    const storyNext = setTimeout(() => {
		setActiveStory(activeStory + 1);
	    }, 7000);
	    return () => clearTimeout(storyNext);
	};
    }, [activeStory, storyInfo, stop, finishStory]);
    const handleClickStoryList = (e, index) => {
	finishStory && setFinishStory(false);
	setActiveStory(index);
    };
    useEffect(() => {
	const fetchCryptoCoinsAdBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/cryptocoinsad/', { 'height_list': height_list });
	    setCryptoCoinsAdInfo(data.info);
	};
	const fetchSurfBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250, 400];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/surfads/', { 'height_list': height_list });
	    setSurfInfo(data.info);
	    if (Object.keys(data.info).length > 0) {
		const ins = document.getElementById('surf1');
		ins.className = 'surfe-be';
		ins.setAttribute('data-sid', data.info.data);
		const script = document.createElement('script');
		script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
		document.body.appendChild(script);
		return new Promise(() => {
		    script.remove();
		});
	    };
	};
	const fetchFPBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/FP/', { 'height_list': height_list });
	    setFPInfo(data.info);
	};
	if (device !== undefined) {
	    fetchCryptoCoinsAdBanner();
	    fetchSurfBanner();
	    fetchFPBanner();
	};
    }, [device]);
    return (
        <>
	    <Helmet>
	        <meta name="description" content="This is a bridge between advertisers and viewers. Where advertisers can promote their business in the virtual world. Where viewers can make money online." />
	        <meta name="keywords" content="online earning, make money online, online job, free crypto, free bitcoin, free btc, paid to click, ptc, legit stake, digital advertising, online advertising, digital ads, online ads, promoting business, icefauce, airdrop, new airdrop" />
	        <title>iceFaucet | earn more crypto + advertising campaign</title>
	    </Helmet>
            <ScrollToTop />
            <HeroHomeSection heroData={pageLang ? langHeroHomeData.find(data => data.lang === pageLang).info : heroHomeData} data={data} />
	    <StoryOutContainer>
	        {storyInfo !== undefined && Object.keys(storyInfo).length > 0 &&
		    <StoryContainer dark={0} stop={stop ? 1 : 0} onContextMenu={(e) => e.preventDefault()}>
			<StoryHeroTitleWrap dark={0}><h2>ice stories</h2></StoryHeroTitleWrap>
			{storyInfo.map((story, index) => (
			    <IceStory key={index} info={story} index={index} activeStory={activeStory} setStop={setStop} dark={0} />
			))}
			<ActiveStoryContainer>
			    {storyInfo.map((story, index) => (
				<ActiveStoryList key={index} active={index === activeStory ? 1 : 0} onClick={(e) => handleClickStoryList(e, index)}>
				    <ActiveStoryImg src={story.logo} alt={index + 1} />
				</ActiveStoryList>
			    ))}
			</ActiveStoryContainer>
		    </StoryContainer>
		}
	    </StoryOutContainer>
	    {FPInfo !== undefined && Object.keys(FPInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#f9f9f9' }}>
		    <iframe title='faucetpay'
		        id={FPInfo.banner_id}
		        src={FPInfo.src}
		        width={FPInfo.width}
		        height={FPInfo.height}
		        style={{ width: `${FPInfo.width}px`, height: `${FPInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
            <InfoSection homeObj={pageLang ? langHomeObjOne.find(data => data.lang === pageLang).info : homeObjOne} />
            <InfoSection homeObj={pageLang ? langHomeObjTwo.find(data => data.lang === pageLang).info : homeObjTwo} />
            <InfoSection homeObj={pageLang ? langHomeObjThree.find(data => data.lang === pageLang).info : homeObjThree} />
            <Features
                featureHead={pageLang ? langFeatureHead.find(data => data.lang === pageLang).info : featureHead}
                featureLang={pageLang ? langHomeFeatures.find(data => data.lang === pageLang).info : homeFeatures}
            />
	    {cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#010606' }}>
		    <iframe title='cryptocoinsad'
		        src={cryptoCoinsAdInfo.src}
		        style={{ width: `${cryptoCoinsAdInfo.width}px`, height: `${cryptoCoinsAdInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {surfInfo !== undefined && Object.keys(surfInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#010606' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
        </>
    )
}
export default HomePage;
