import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import ScrollToTop from '../components/ScrollToTop';
import HeroSection from '../components/HeroSection';
import HowEarn from '../components/HowEarn';
import Options from '../components/Options';
import OfferWalls from '../components/OfferWalls';
import GateWays from '../components/GateWays';
import { heroMakeMoneyData, langHeroMakeMoneyData } from '../data/heroSectionData';
import { makeMoneyFeatures, langMakeMoneyFeatures } from '../data/makeMoneyFeatures';
import { howEarnData, langHowEarnData, howEarnEndData, langHowEarnEndData, offerwallsData, langOfferwallsData, langGatewayData, gatewayData } from '../data/howEarnData';
import IceStory from '../components/IceStory';
import { StoryOutContainer, StoryContainer, StoryHeroTitleWrap, ActiveStoryContainer, ActiveStoryList, ActiveStoryImg } from '../components/IceStory/IceStoryElements';

const MakeMoneyPage = () => {
    const pageLang = localStorage.getItem('pageLang');
    const [device, setDevice] = useState();
    const [cryptoCoinsAdInfo, setCryptoCoinsAdInfo] = useState();
    const [surfInfo, setSurfInfo] = useState();
    const [FPInfo, setFPInfo] = useState();
    const [storyInfo, setStoryInfo] = useState();
    const [activeStory, setActiveStory] = useState(0);
    const [stop, setStop] = useState(false);
    const [finishStory, setFinishStory] = useState(false);
    const deviceScreenhandler = () => {
        if (window.innerWidth < 880) {
	    setDevice('M');
	} else {
	    setDevice('D');
	};
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    useEffect(() => {
	const fetchStoryInfo = async () => {
	    const { data } = await axios.get('https://icefaucet.com/api/story/view/out/');
	    setStoryInfo(data.info);
	};
	fetchStoryInfo();
    }, []);
    useEffect(() => {
	if (storyInfo !== undefined && Object.keys(storyInfo).length > 0 && !stop && !finishStory) {
	    if (activeStory === Object.keys(storyInfo).length) {
		setFinishStory(true);
		return;
	    };
	    const storyNext = setTimeout(() => {
		setActiveStory(activeStory + 1);
	    }, 7000);
	    return () => clearTimeout(storyNext);
	};
    }, [activeStory, storyInfo, stop, finishStory]);
    const handleClickStoryList = (e, index) => {
	finishStory && setFinishStory(false);
	setActiveStory(index);
    };
    useEffect(() => {
	const fetchCryptoCoinsAdBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/cryptocoinsad/', { 'height_list': height_list });
	    setCryptoCoinsAdInfo(data.info);
	};
	const fetchSurfBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250, 400];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/surfads/', { 'height_list': height_list });
	    setSurfInfo(data.info);
	    if (Object.keys(data.info).length > 0) {
		const ins = document.getElementById('surf1');
		ins.className = 'surfe-be';
		ins.setAttribute('data-sid', data.info.data);
		const script = document.createElement('script');
		script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
		document.body.appendChild(script);
		return new Promise(() => {
		    script.remove();
		});
	    };
	};
	const fetchFPBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/FP/', { 'height_list': height_list });
	    setFPInfo(data.info);
	};
	if (device !== undefined) {
	    fetchCryptoCoinsAdBanner();
	    fetchSurfBanner();
	    fetchFPBanner();
	};
    }, [device]);
    return (
        <>
	    <Helmet>
	        <meta name="description" content="Green ways to make money online" />
	        <meta name="keywords" content="earn money online, earn crypto online, earn free crypto, affiliate marketing, affiliate program, ptc website, ptc site, ptc, free dogecoin, free tron, free tether, free dollars, view ads to earn, airdrop, new airdrop" />
	        <title>iceFaucet | make money online</title>
	    </Helmet>
            <ScrollToTop />
            <HeroSection heroData={pageLang ? langHeroMakeMoneyData.find(data => data.lang === pageLang).info : heroMakeMoneyData} />
	    <StoryOutContainer style={{ background: '#010606' }}>
	        {storyInfo !== undefined && Object.keys(storyInfo).length > 0 &&
		    <StoryContainer dark={1} stop={stop ? 1 : 0} onContextMenu={(e) => e.preventDefault()}>
			<StoryHeroTitleWrap dark={1}><h2>ice stories</h2></StoryHeroTitleWrap>
			{storyInfo.map((story, index) => (
			    <IceStory key={index} info={story} index={index} activeStory={activeStory} setStop={setStop} dark={0} />
			))}
			<ActiveStoryContainer>
			    {storyInfo.map((story, index) => (
				<ActiveStoryList key={index} active={index === activeStory ? 1 : 0} onClick={(e) => handleClickStoryList(e, index)}>
				    <ActiveStoryImg src={story.logo} alt={index + 1} />
				</ActiveStoryList>
			    ))}
			</ActiveStoryContainer>
		    </StoryContainer>
		}
	    </StoryOutContainer>
	    {FPInfo !== undefined && Object.keys(FPInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#010606' }}>
		    <iframe title='faucetpay'
		        id={FPInfo.banner_id}
		        src={FPInfo.src}
		        width={FPInfo.width}
		        height={FPInfo.height}
		        style={{ width: `${FPInfo.width}px`, height: `${FPInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
            <Options options={pageLang ? langMakeMoneyFeatures.find(data => data.lang === pageLang).info : makeMoneyFeatures} extra={false} />
            <HowEarn
                howEarnData={pageLang ? langHowEarnData.find(data => data.lang === pageLang).info : howEarnData}
                howEarnEndData={pageLang ? langHowEarnEndData.find(data => data.lang === pageLang).info : howEarnEndData}
            />
	    <OfferWalls offerData={pageLang ? langOfferwallsData.find(data => data.lang === pageLang).info : offerwallsData} />
	    <GateWays gateData={pageLang ? langGatewayData.find(data => data.lang === pageLang).info : gatewayData} />
	    {surfInfo !== undefined && Object.keys(surfInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#f9f9f9' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
	    {cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', background: '#f9f9f9' }}>
		    <iframe title='cryptocoinsad'
		        src={cryptoCoinsAdInfo.src}
		        style={{ width: `${cryptoCoinsAdInfo.width}px`, height: `${cryptoCoinsAdInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
        </>
    )
}
export default MakeMoneyPage;
