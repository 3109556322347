import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { miningSetUpAction, miningSetUpEndAction, miningSuccessAction } from '../../actions/miningSetUpAction';
import axiosInstance from '../../axios';
import { PtcForm, PtcFormInputContainer } from '../SetUpPtc/SetUpPtcFormElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { FilterLangWrap } from '../SetUpPtc/SetUpPtcFormElements.js';
import { TinyFormButton } from "../ButtonElements";
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';

const SetUpMiningEnd = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const visit = parseInt(localStorage.getItem('visit'));
    const totalCost = visit * 4;
    const upcodeValue = Math.floor(visit * (parseFloat(localStorage.getItem('upcode')) - 1.00));
    const remainAdscoin = localStorage.getItem('adcoin') - totalCost;
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    };
    const handleBack = () => {
        dispatch(miningSetUpEndAction({ prop: false }));
        dispatch(miningSetUpAction({ prop: true }));
    };
    const fetchMiningAds = async () => {
        const { data } = await axiosInstance.post('ptc/auto/setup/', {
            url: localStorage.getItem('url'),
            title: localStorage.getItem('title'),
            visit: visit,
            status: autoplay ? 'P' : 'S'
        });
        if (data.status) {
            localStorage.removeItem('url');
            localStorage.removeItem('title');
            localStorage.removeItem('visit');
            localStorage.removeItem('adcoin');
            localStorage.removeItem('upcode');
            dispatch(miningSetUpEndAction({ prop: false }));
            dispatch(miningSuccessAction({ prop: true }));
        };
    };
    useEffect(() => {
        if (isSubmitting) {
            fetchMiningAds();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>cost of your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                CPI (Cost Per Impression) = <strong>4 adscoin</strong><br />
                                Number of visits = <strong>{visit} visits</strong><br />
                                The total cost of your ads = <strong>{totalCost} adscoin</strong> <br />
                                Remain your adscoin = <strong>{remainAdscoin} adscoin</strong> <br />
                                Number of upcode visit as gift = {totalCost >= 250000 ? <strong>{upcodeValue} visits</strong> : 0}
                            </Instruction>
                        </InstructionWrap>
                    </FilterContainer>
                    <FormHeader>
                        <h2>autoplay your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you want to show your ads instantly after acceptance in the monitoring process, press the following button.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={() => setAutoplay(!autoplay)} itemActive={autoplay ? 1 : 0}>
                                    Accept
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        complete
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
};
export default SetUpMiningEnd;
