export const userSocialMedia = [
    {
	'social': 'instagram',
	'src': 'https://icefaucet.com/media/help/blog/instagram.png'
    },
    {
	'social': 'x',
	'src': 'https://icefaucet.com/media/help/blog/x.png'
    },
    {
	'social': 'youtube',
	'src': 'https://icefaucet.com/media/help/blog/youtube.png'
    }
];
