import React from 'react';
import { Container, Wrapper, SubjectWrap, EditWrap, HeaderContainer, HeaderContentWrap, HeaderArticle, BackArticle, NextArticle, BackIcon, NextIcon, SectionWrap, ImgArticleWrap, VideoLinkWrap, DescWrap, ArticleListContainer, ArticleListHeader, ListContainer, ListNameLink, SectionContainer, SectionTitle, SectionContentWrap, SectionWrapper, ImgSectionWrap, DescSectionWrap } from './ArticleElements.js';
import youtube from '../../images/youtube.svg';
import { TinyButtonAnchor } from '../ButtonElements';
import moment from 'moment';

const Article = ({ data, small, dark, articleTimer }) => {
    return (
	<Container dark={dark}>
	    <Wrapper>
		<HeaderContainer>
		    <HeaderContentWrap>
	    		<HeaderArticle>
	                    {data.prev_article && articleTimer === undefined &&
				<BackArticle href={data.prev_article}>
				    <BackIcon />
				</BackArticle>
			    }
	                    <SubjectWrap dark={dark}>
	                        <h1>{data.title}</h1>
	                        <EditWrap>
	                            <h3>last edit:</h3>
	                            <h3>{moment(data.edit).fromNow()}</h3>
	                        </EditWrap>
	                    </SubjectWrap>
	                    {data.next_article && articleTimer === undefined &&
				<NextArticle href={data.next_article}>
				    <NextIcon />
				</NextArticle>
			    }
	                </HeaderArticle>
	                <SectionWrap>
	                    <ImgArticleWrap>
	                        <img src={data.cover ? data.cover : data.cover_url} alt={data.title} />
	                        {data.video_link &&
				    <VideoLinkWrap>
					{!small &&
					    <img src={youtube} alt='youtube video' />
					}
					<TinyButtonAnchor href={data.video_link} primary={1} style={{ margin: 'auto' }}>watch on YouTube</TinyButtonAnchor>
				    </VideoLinkWrap>
				}
	                    </ImgArticleWrap>
	                    <DescWrap dark={dark}>
	                        <p>{data.abstract}</p>
	                    </DescWrap>
	                </SectionWrap>
	            </HeaderContentWrap>
	    	    <ArticleListContainer>
	        	<ArticleListHeader dark={dark}>
	            	    <h2>article list</h2>
	        	</ArticleListHeader>
	        	{data.article_contents !== undefined && data.article_contents.map((section, index) => (
			    <ListContainer key={index} dark={dark}>
			        <li>
			            <ListNameLink to={section.header} dark={dark} smooth={true} duration={500} spy={true} offset={0} activeClass='active'>
			                {section.header}
			            </ListNameLink>
			        </li>
			    </ListContainer>
			))}
	    	    </ArticleListContainer>
	    	</HeaderContainer>
	        {data.article_contents !== undefined && data.article_contents.map((section, index) => (
		    <SectionContainer key={index} primary={index % 2 === 0 ? 0 : 1} id={section.header}>
			<SectionTitle primary={index % 2 === 0 ? 0 : 1}>
			    <h2>{section.header}</h2>
			</SectionTitle>
		        <SectionContentWrap>
			    <SectionWrapper>
			        <ImgSectionWrap primary={index % 2 === 0 ? 0 : 1}>
			            <img src={section.image ? section.image : section.image_url} alt={data.title + index} />
			        </ImgSectionWrap>
			    </SectionWrapper>
			    <DescSectionWrap primary={index % 2 === 0 ? 0 : 1}>
			        <p>{section.desc}</p>
			       	{section.link &&
				    <TinyButtonAnchor href={section.link} style={{ marginTop: '10px' }}>view link</TinyButtonAnchor>
				}
			    </DescSectionWrap>
			</SectionContentWrap>
		    </SectionContainer>
		))}
	    </Wrapper>
	</Container>
    )
}
export default Article;
