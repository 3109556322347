import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axiosInstance from '../axios';
import { useParams, useHistory } from 'react-router-dom';
import Article from '../components/Article';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Container, Wrapper, DayIcon, NightIcon, UpIcon, TimerContainer, TextWrap, TimerWrap, Timer } from '../components/Article/ArticleElements';
import { animateScroll as scroll } from 'react-scroll';
import { TinyFormButton } from '../components/ButtonElements';
import IcefaCaptcha from '../components/Captcha/IcefaCaptcha';

const ArticlePage = () => {
    const params = useParams();
    const history = useHistory();
    const title = params.title;
    const [loading, setLoading] = useState(true);
    const [infoArticle, setInfoArticle] = useState();
    const [apiError, setApiError] = useState('');
    const [smallScreenSize, setSmallScreenSize] = useState(false);
    const [dark, setDark] = useState(localStorage.getItem('lightness') ? true : false);
    const [showUpIcon, setShowUpIcon] = useState(false);
    const [articleTimer, setArticleTimer] = useState();
    const [cardID, setCardID] = useState();
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const [miningDone, setMiningDone] = useState(false);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 480) {
	    setSmallScreenSize(true);
	} else {
	    setSmallScreenSize(false);
	};
    };
    const showUpIconHandler = () => {
	if (window.scrollY > 200) {
	    setShowUpIcon(true);
	} else {
	    setShowUpIcon(false);
	};
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    useEffect(() => {
	window.addEventListener('scroll', showUpIconHandler);
    });
    const fetchArticle = async () => {
	try {
	    const { data } = await axiosInstance.get(`article/related/${title}/`);
	    setInfoArticle(data.info);
	    data.timer > 0 && setArticleTimer(data.timer);
	    data.card_id > 0 && setCardID(data.card_id);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    useEffect(() => {
	infoArticle === undefined && !apiError && fetchArticle();
    });
    const lightnessClick = (status) => {
	if (status === 'dark') {
	    localStorage.setItem('lightness', 'dark');
	    setDark(true);
	} else {
	    localStorage.removeItem('lightness');
	    setDark(false);
	};
    };
    useEffect(() => {
	if (articleTimer !== undefined) {
	    if (articleTimer === 0) {
		return;
	    };
	    const timer = setTimeout(() => {
	        setArticleTimer(articleTimer - 1);
	    }, 1000);
	    return () => clearTimeout(timer);
	};
    });
    useEffect(() => {
	if (captchaDone && !miningDone && !apiError) {
	    fetchCradMiningDone();
	};
    });
    const fetchCradMiningDone = async () => {
	try {
	    await axiosInstance.post('mining/card/done/', {
		'id': Object.freeze(cardID)
	    });
	    setMiningDone(true);
	    setTimeout(() => history.push('/card-mining'), 1500)
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    return (
	<Container dark={dark}>
	    <Wrapper>
	        <Helmet>
	            <meta name="description" content={infoArticle !== undefined && infoArticle.meta_desc} />
	            <meta name="keywords" content={infoArticle !== undefined && infoArticle.meta_keywords} />
	            <title>{infoArticle !== undefined && infoArticle.title}</title>
	        </Helmet>
	        {loading &&
		    <div style={{ margin: 'auto' }}>
			<Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		    </div>
		}
	        {!loading && apiError &&
		    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
			<MsgText primary={0}>
			    {apiError}
			</MsgText>
		    </MsgWrap>
		}
	        {infoArticle !== undefined && !apiError && articleTimer !== undefined && cardID !== undefined &&
		    <TimerContainer>
			<TextWrap primary={1}>
			    Scroll down and press the "Complete" button:
			</TextWrap>
		    	<TimerWrap>
			    <Timer>
				{articleTimer === 0 ? 'scroll down' : articleTimer + ' sec'}
			    </Timer>
			</TimerWrap>
		    </TimerContainer>
		}
	        {showUpIcon ? <UpIcon onClick={() => scroll.scrollToTop()} /> : null}
	        {dark ? <NightIcon title='dark' onClick={() => lightnessClick('light')} /> : <DayIcon title='light' onClick={() => lightnessClick('dark')} />}
	        {infoArticle !== undefined && !apiError &&
		    <Article data={infoArticle} small={smallScreenSize} dark={dark ? 1 : 0} articleTimer={articleTimer} />
		}
	        {infoArticle !== undefined && !apiError && articleTimer !== undefined && cardID !== undefined && !iceCaptcha &&
		    <TinyFormButton primary={0} big={0} onClick={articleTimer === 0 ? () => setIceCaptcha(true) : null}>
			{articleTimer === 0 ? 'complete' : 'please wait...'}
		    </TinyFormButton>
		}
	        {infoArticle !== undefined && !apiError && articleTimer !== undefined && cardID !== undefined && iceCaptcha && !captchaDone &&
		    <IcefaCaptcha appName='card_mining' setCaptchaDone={setCaptchaDone} dark={0} />
		}
	        {miningDone &&
		    <MsgWrap primary={1} style={{ width: '80%', margin: ' 15px auto' }}>
			<MsgText primary={1}>
			    Your mining was successfully done.
			</MsgText>
		    </MsgWrap>
		}
	    </Wrapper>
	</Container>
    )
}
export default ArticlePage;
