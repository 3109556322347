import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { CaptchaContainer, CaptchaWrap, QuestionsWrap, ChoicesContainer, ChoicesWrap, ChoicesImg, ChoicesTxt } from './IcefaCaptchaElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { Circle } from '../Loading';

const IcefaCaptcha = ({ appName, setCaptchaDone, dark }) => {
    const history = useHistory();
    const [info, setInfo] = useState();
    const [error, setError] = useState('');
    const [choicesName, setChoicesName] = useState('');
    const [choicesIndex, setChoicesIndex] = useState('');
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const handleChoice = (e, choiceIndex) => {
        const choicesNameTarget = e.target.getAttribute('name');
	if (choicesNameTarget && choicesNameTarget !== choicesName) {
	    setChoicesName(choicesNameTarget);
	    setChoicesIndex(choiceIndex);
	};
    };
    const handleComplete = () => {
	if (choicesName.length > 0 && !loading && !apiError) {
	    fetchCaptchaCheck();
	};
    };
    useEffect(() => {
	const fetchCaptcha = async () => {
	    try {
		const { data } = await axiosInstance.get('captcha/');
		setInfo(data.data);
	    } catch (error) {
		error.response && error.response.data.timer ? setError(error.response.data.timer) : setError(error.message);
	    };
	    setLoading2(false);
	};
	fetchCaptcha();
    }, []);
    const fetchCaptchaCheck = async () => {
	setLoading(true);
	try {
	    await axiosInstance.post('captcha/check/', {
		'answer': choicesName,
		'app': appName
	    });
	    setLoading(false);
	    return setCaptchaDone(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    appName !== 'auto-ads' && appName !== 'card_mining' && setTimeout(() => history.go(0), 1000);
	    appName === 'auto-ads' && setTimeout(() => history.push('/auto'), 1000);
	    appName === 'card_mining' && setTimeout(() => history.push('/card-mining'), 1000);
	    setLoading(false);
	};
    };
    return (
	<CaptchaContainer dark={dark} surf={appName === 'surf' ? 1 : 0}>
	    <CaptchaWrap dark={dark}>
	        {loading2 &&
		    <Circle style={{ width: '25px', height: '25px', margin: '25px auto' }} />
		}
	    	{!loading2 &&
		    <>
	        	<QuestionsWrap>
	            	    {info !== undefined && !error &&
			        <p>{info.question}</p>
		    	    }
	        	</QuestionsWrap>
	    		<ChoicesContainer>
	            	    <ChoicesWrap>
	                	{info !== undefined && !error && info.choices_a !== undefined && info.choices_a.map((choice, choiceIndex) => (
			    	    <ChoicesImg key={choiceIndex} active={choiceIndex === choicesIndex ? 1 : 0}>
			        	<img src={choice.image_url ? choice.image_url : choice.image} alt={choice.alt} name={choice.alt} onClick={(e) => handleChoice(e, choiceIndex)} />
			    	    </ChoicesImg>
				))}
	                	{info !== undefined && !error && info.choices_bc !== undefined && info.choices_bc.map((choice, choiceIndex) => (
			    	    <ChoicesTxt key={choiceIndex} active={choiceIndex === choicesIndex ? 1 : 0}>
			        	<span name={choice.choice} onClick={(e) => handleChoice(e, choiceIndex)}>
			            	    {choice.choice}
			        	</span>
			    	    </ChoicesTxt>
				))}
	            	    </ChoicesWrap>
	        	</ChoicesContainer>
		    </>
		}
	        {!loading && apiError &&
	            <MsgWrap style={{ width: '80%', maxWidth: '500px' }} primary={0}>
	                <MsgText primary={0}>
	                    {apiError}
	                </MsgText>
	            </MsgWrap>
	        }
	        {error &&
		    <MsgWrap style={{ width: '80%', maxWidth: '500px' }} primary={0}>
			<MsgText primary={0}>
			    {error}
			</MsgText>
		    </MsgWrap>
		}
	        {choicesName.length > 0 && !loading && !apiError &&
		    <TinyFormButton
			type='button'
			style={{ width: '80%', maxWidth: '500px' }}
			big={0}
			primary={1}
			onClick={handleComplete}
		    >
			complete
		    </TinyFormButton>
		}
	        {loading &&
		    <Circle style={{ width: '20px', height: '20px', margin: '10px auto' }} />
		}
	    </CaptchaWrap>
	</CaptchaContainer>
    )
}
export default IcefaCaptcha;
