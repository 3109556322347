import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const SocialContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 25px;
    opacity: ${({ active }) => (active ? '1' : '0.6')};
    cursor: ${({ cursor }) => (cursor ? 'pointer' : 'auto')};
`
export const SocialWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : 'rgba(39, 117, 85, 0.4)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 15px;
`
export const HeaderWrap = styled.div`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 15px 15px 0 0;
`
export const LeftWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const SmallLeftWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border-radius: 10px 10px 0 0;
`
export const ItemWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border-radius: 10px 10px 0 0;
    padding: 5px;
    margin-right: 5px;
    h3 {
	font-size: clamp(0.65rem, 2vw, 0.9rem);
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
	letter-spacing: 1.1px;
	text-align: center;
    }
`
export const SmallItemWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    h3 {
	font-size: 0.65rem;
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
	letter-spacing: 1.1px;
	text-align: center;
    }
`
export const ProfileWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ProfileImgWrap = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    border-radius: 50%;
    color: #fff;
    font-size: 0.7rem;
    margin-right: 7px;
    img {
	width: 90%;
	height: 90%;
	position: absolute;
	border-radius: 50%;
    }
`
export const UsernameWrap = styled.div`
    h3 {
	font-size: clamp(0.8rem, 2vw, 1.1rem);
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	letter-spacing: 1.1px;
	text-align: center;
    }
`
export const GiftCodeWrap = styled.div`
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: space-between:
    align-items: center;
    margin: 15px auto;
`
export const SmallGiftCodeWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center:
    align-items: center;
    margin: 15px auto;
`
export const StuffWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    padding: 0 %25;
`
export const SocialLogo = styled.img`
    width: 40px;
    height: 40px;
    margin: auto;
`
export const ProgressBarContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const ProgressBarWrap = styled.div`
    height: 13px;
    margin: 5px 20px;
    display: flex;
    align-items: center;
    text-align: center;
    outline: 2px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 0 0 30px 0;
    span {
	position: absolute;
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	font-size: clamp(0.6rem, 2vw, 0.75rem);
	padding-left: 4px;
    }
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 13px;
    background: rgba(1, 219, 187, 0.9);
    border-radius: 0 0 30px 0;
`
export const PriceWrap = styled.div`
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border-radius: 10px;
    padding: 5px 0;
    margin: auto;
    h3 {
	font-size: clamp(0.7rem, 2vw, 0.9rem);
	color: rgba(1, 219, 187, 1);
	text-align: center;
    }
`
export const DescriptionWrap = styled.div`
    width: 100%;
    padding-left: 15px;
    p {
	color: #fff;
	font-size: clamp(0.8rem, 2vw, 0.95rem);
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
	line-height: 2;
	letter-spacing: 1.2px;
	word-spacing: 1.7px;
    }
`
export const CodeWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
`
export const NumberFormatCode = styled(NumberFormat)`
    color: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : 'rgba(39, 117, 85, 1)')};
    font-size: clamp(1rem, 2vw, 1.2rem);
    font-weight: bold;
    max-width: 160px;
    width: 100%;
    text-align: center;
    &:focus {
	outline: 2px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : 'rgba(39, 117, 85, 1)')};
    }
`
export const TimerWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 0 0 10px 10px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    h3 {
	color: rgba(1, 219, 187, 1);
	font-size: clamp(0.65rem, 2vw, 0.8rem);
	letter-spacing: 1.3px;
	margin-right: 15px;
    }
`
export const Timer = styled.h4`
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.65rem, 2vw, 0.8rem);
    letter-spacing: 1.3px;
`
