import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { BigContainer, BannerWrap, Container, Wrapper } from './DesktopBannerElements';
import Sponsor from '../Sponsor';
import { MsgText, MsgWrap } from '../MessageElements';
import { useHistory } from 'react-router-dom';
import { TinyButtonSurf } from '../ButtonElements';
import { Circle } from '../Loading';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';

const DesktopBanner = () => {
    const history = useHistory();
    const [banner728Data, setBanner728Data] = useState();
    const [bannerOthersData, setBannerOthersData] = useState();
    const [sponsorData, setSponsorData] = useState();
    const [cryptoCoinsAdInfo1, setCryptoCoinsAdInfo1] = useState();
    const [cryptoCoinsAdInfo2, setCryptoCoinsAdInfo2] = useState();
    const [surfBannerInfo1, setSurfBannerInfo1] = useState();
    const [surfBannerInfo2, setSurfBannerInfo2] = useState();
    const [error, setError] = useState('');
    const [buttonTimer, setButtonTimer] = useState(10);
    const [isFetching, setIsFetching] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();
    const [needCaptcha, setNeedCaptcha] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const fetchBanner = async () => {
	setIsFetching(true);
        const { data } = await axiosInstance.get('banner/view/desktop/');
	setNeedCaptcha(data.need_captcha);
        setBanner728Data(data.info_728);
        setBannerOthersData(data.info_others);
	setCryptoCoinsAdInfo1(data.cryptocoinsad_info1);
	setCryptoCoinsAdInfo2(data.cryptocoinsad_info2);
	setSurfBannerInfo1(data.surf_info1);
	setSurfBannerInfo2(data.surf_info2);
	data.info_728 === null && data.surf_info1 !== null && scriptSurf1(data.surf_info1);
        fetchSponsor(data);
    };
    const fetchBannerClicked = async (id) => {
        await axiosInstance.post('banner/clicked/', {
            'id': id
        });
    };
    const fetchSponsor = async (info) => {
        try {
            const { data } = await axiosInstance.get('sponsor/view/');
            setSponsorData(data.info);
        } catch (error) {
        };
	setIsFetched(true);
	info.info_others === null && info.surf_info2 !== null && scriptSurf2(info.surf_info2);
    };
    const scriptSurf1 = (info) => {
	const ins = document.getElementById('surf1');
	ins.className = 'surfe-be';
	ins.setAttribute('data-sid', info.data);
	const script = document.createElement('script');
	script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	document.body.appendChild(script);
	return new Promise(() => {
	    script.remove();
	});
    };
    const scriptSurf2 = (info) => {
	const ins = document.getElementById('surf2');
	ins.className = 'surfe-be';
	ins.setAttribute('data-sid', info.data);
	const script = document.createElement('script');
	script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	document.body.appendChild(script);
	return new Promise(() => {
	    script.remove();
	});
    };
    const fetchBannerMining = async () => {
	setLoading(true);
        try {
            const { data } = await axiosInstance.get('mining/view/banner/');
            if (data.icecoin !== undefined) {
                setInfo(data);
                setTimeout(() => history.go(0), 1000);
            }
        } catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
	setLoading(false);
    };
    const handleBannerClicked = (id) => {
        fetchBannerClicked(id);
    };
    const handleRightClick = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        if (!isFetching && banner728Data === undefined && bannerOthersData === undefined) {
            fetchBanner();
        };
    });
    useEffect(() => {
        if (isFetched && banner728Data === null && bannerOthersData === null && sponsorData.length === 0 && cryptoCoinsAdInfo1 === null && cryptoCoinsAdInfo2 === null && surfBannerInfo1 === null && surfBannerInfo2 === null) {
            setError('There are not any advertisements to continue mining, come back later.')
        };
    }, [isFetched, banner728Data, bannerOthersData, sponsorData, cryptoCoinsAdInfo1, cryptoCoinsAdInfo2, surfBannerInfo1, surfBannerInfo2]);
    useEffect(() => {
        if (buttonTimer === 0 && !error) {
            if (needCaptcha) {
		setIceCaptcha(true);
		return;
	    };
        };
        if (buttonTimer > 0 && !error) {
            const timer = setTimeout(() => {
                setButtonTimer(buttonTimer - 1);
            }, 1000);
            return () => clearTimeout(timer);
        };
    }, [needCaptcha, buttonTimer, error]);
    useEffect(() => {
	if (captchaDone) {
	    setIceCaptcha(false);
	};
    }, [captchaDone]);
    return (
        <>
	    {isFetched && banner728Data === null && cryptoCoinsAdInfo1 !== undefined && cryptoCoinsAdInfo1 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={cryptoCoinsAdInfo1.src}
		        style={{ width: `${cryptoCoinsAdInfo1.width}px`, height: `${cryptoCoinsAdInfo1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {surfBannerInfo1 !== undefined && surfBannerInfo1 !== null &&
		<div style={{ margin: '10px auto' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
	    {isFetched && (banner728Data !== null || bannerOthersData !== null || sponsorData.length > 0 || (cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null) || (surfBannerInfo2 !== undefined && surfBannerInfo2 !== null)) &&
                <BigContainer>
                    {banner728Data !== null &&
                        <BannerWrap href={banner728Data.url} target='_blank' onClick={() => handleBannerClicked(banner728Data.banner_id)} onContextMenu={handleRightClick}>
                            <img src={banner728Data.banner_img ? banner728Data.banner_img : banner728Data.banner_url ? banner728Data.banner_url : null} alt={banner728Data.title} title={banner728Data.url.split('/')[2]} />
                        </BannerWrap>
                    }
                    <Container>
                        <Wrapper state={(bannerOthersData !== null || (cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null) || (surfBannerInfo2 !== undefined && surfBannerInfo2 !== null)) && sponsorData.length !== 0
                            ? 1
                            : sponsorData.length !== 0
                                ? 2
                                : bannerOthersData !== null || (cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null) || (surfBannerInfo2 !== undefined && surfBannerInfo2 !== null)
                                    ? 3
                                    : null}>
			    {bannerOthersData === null && cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null &&
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto 25px auto' }}>
				    <iframe title='cryptocoinsad'
				        src={cryptoCoinsAdInfo2.src}
				        style={{ width: `${cryptoCoinsAdInfo2.width}px`, height: `${cryptoCoinsAdInfo2.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
				    </iframe>
				</div>
			    }
		    	    {surfBannerInfo2 !== undefined && surfBannerInfo2 !== null &&
				<div style={{ margin: 'auto auto 25px auto' }}>
				    <ins id='surf2'></ins>
				</div>
			    }
		    	    {
                                bannerOthersData !== null &&
                                <div style={{ display: 'flex' }}>
                                    <BannerWrap href={bannerOthersData.url} target='_blank' onClick={() => handleBannerClicked(bannerOthersData.banner_id)} onContextMenu={handleRightClick}>
                                        <img src={bannerOthersData.banner_img ? bannerOthersData.banner_img : bannerOthersData.banner_url ? bannerOthersData.banner_url : null} alt={bannerOthersData.title} title={bannerOthersData.url.split('/')[2]} />
                                    </BannerWrap>
                                </div>
                            }
                            {
                                sponsorData.length !== 0 &&
                                <div style={{ marginBottom: '25px' }}>
                                    <Sponsor data={sponsorData} />
                                </div>
                            }
                        </Wrapper>
                    </Container>
                </BigContainer >
            }
            {error &&
                <MsgWrap primary={0} style={{ width: '80%', margin: '10px auto', textAlign: 'center' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
            {info !== undefined &&
                <MsgWrap primary={1} style={{ width: '80%', margin: '0 auto 15px auto', textAlign: 'center' }}>
                    <MsgText primary={1}>
                        You mined {info.icecoin} icecoins.
                    </MsgText>
                </MsgWrap>}
            {!error && isFetched && info === undefined && !iceCaptcha &&
                <TinyButtonSurf
                    style={{ width: '80%', margin: '0 auto 15px auto' }}
                    type='button'
                    onClick={buttonTimer === 0 && !loading ? fetchBannerMining : null}
                    onContextMenu={handleRightClick}
                    big={1}
                >
		    {buttonTimer > 0 ? buttonTimer : loading ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : 'click'}
                </TinyButtonSurf>
            }
	    {iceCaptcha && !captchaDone &&
		<IcefaCaptcha appName='banner-mining' setCaptchaDone={setCaptchaDone} dark={0} />
	    }
        </>
    )
}
export default DesktopBanner;
