import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import Redeem from '../components/Redeem';
import UserSocial from '../components/Redeem/UserSocial';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';

const SocialMarketingPage = () => {
    const [loading, setLoading] = useState(true);
    const [userSocial, setUserSocial] = useState();
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchUserSocialMedia = async () => {
	    try {
		const { data } = await axiosInstance.get('redeem/user-social/');
		setUserSocial(data.info);
		fetchRedeemInfo();
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
		setLoading(false);
	    };
	};
	fetchUserSocialMedia();
    }, []);
    const fetchRedeemInfo = async () => {
	try {
	    const { data } = await axiosInstance.get('redeem/info/');
	    setInfo(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total ads</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.count}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total paid</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.paid} USD</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			    	First, enter your real social media username. Once confirmed, the related ads will be unlocked and you can start earning.<br />
			        Inform us if you want to change your usernames.<br />
			        Advertisers can decline and block you, so complete their requests honestly.<br />
			        If you keep cheating, you will be terminated from the "Social Marketing" app.<br />
			        ** Learn the related terms below: <br />
			        COMMENT: Comment your feelings and ideas on their post or video.<br />
			        FOLLOW: Follow their social media. <br />
			        LIKE: Like their post or video. <br />
			        SUBSCRIBE: Subscribe to their social media. <br />
			        REVIEW: After the advertiser approves, the amount will be added to your account. Advertisers have a limited time (24, 48, or 72 hours) to be approved. After the time expires, the amount is automatically added to the users' accounts.<br />
			        UP-LINE: Your upline has advertised.<br />
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer >
	    }
	    {!loading && !apiError && userSocial !== undefined &&
		<UserSocial info={userSocial} />
	    }
	    {!loading && !apiError && info !== undefined && info.info !== undefined && info.info.map((ads, index) => (
		<Redeem key={index} ads={ads} index={index} />
	    ))}
	    {!loading && !apiError && info !== undefined && info.info === undefined &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '25px auto' }}>
	    	    <MsgText primary={0}>
			Currently, there are not any ads, come back later!
		    </MsgText>
		</MsgWrap>
	    }
	    {apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: ' 15px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	</>
    )
}
export default SocialMarketingPage;
