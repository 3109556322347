import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const UserSocialContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const UserSocialWrap = styled.div`
    width: 80%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.3);
    border: 1px solid rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 15px;
    margin: 25px;
    @media screen and ${device.md} {
	width: 90%;
    }
    @media screen and ${device.sm} {
	width: 95%;
    }
`
export const UserSocialHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    background: rgba(39, 117, 85, 0.6);
    h2 { 
	color: #01DBBB;
	font-size: clamp(0.7rem, 2.4vw, 1rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const UserSocialMainWrap = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    padding: 15px;
`
export const MediaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.5);
    border-radius: 5px;
    margin: 0 auto 10px auto;
    padding: 5px;
`
export const MediaWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const MediaWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
`
export const MediaImgWrap = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(39, 117, 85, 0.75);
    border: rgba(39, 117, 85, 0.5);
    box-shadow: rgba(39, 117, 85, 0.7);
    border-radius: 50%;
    color: #fff;
    font-size: 0.6rem;
    margin: auto;
    @media screen and ${device.tn} {
	width: 30px;
	height: 30px;
    }
`
export const MediaImg = styled.img`
    width: 90%;
    height: 90%;
    position: absolute;
    border-radius: 50%;
`
export const InputWrap = styled.div`
    width:66%;
    display: flex;  
    align-items: center;
    margin: auto;
    @media screen and ${device.sm} {
	width: 55%;
    }
    @media screen and ${device.tn} {
	width: 50%;
    }
`
export const Input = styled.input`
    width:100%;
    padding: 2px 0;
    border: none;
    border-radius: 20px;
    font-size: clamp(0.75rem, 2vw, 0.95rem);
    color: #277555;
    ::placeholder {
	color: rgba(39, 117, 85, 0.8);
    }
    :disabled {
	background: rgba(10, 10, 10, 0.2);
	opacity: 1;
    }
    &:focus {
	outline: #277555;
    }
`
export const StatusWrap = styled.div`
    width: 70px;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5)=;
    padding: 5px 2px;
    margin: auto;
    border-radius: 5px;
    @media screen and ${device.sm} {
	width: 60px;
    }
    h3 {
	color: rgba(1, 219, 187, 1);
	font-size: 0.75rem;
	font-weight: 400;
	text-align: center;
    }
`
