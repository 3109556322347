import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { CategoryWrap, TitleWrap, IconWrap, DownIcon, UpIcon } from './FAQElements';
import { BlogWrap, BlogCategoryWrap, BlogCategory, BlogCard, BlogTitleWrap, BlogContentWrap, ImgWrap, ContentWrap, DiscWrap } from './BlogElements';
import { TinyButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements.js';
import Ticket from '../Ticket';

const Blog = ({ categoryBlog, setCategoryBlog, BlogCategories }) => {
    const params = useParams();
    const location = useLocation();
    const [infoBlog, setInfoBlog] = useState();
    const [error, setError] = useState('');
    const [arrowClick, setArrowClick] = useState(false);
    const [arrowIndex, setArrowIndex] = useState();
    const authorized = localStorage.getItem('access_token') ? true : false;
    const handleArrowClick = (categoryTarget, index) => {
        if (categoryTarget && categoryTarget !== categoryBlog) {
            setCategoryBlog(categoryTarget);
        };
        if (arrowIndex === undefined) {
            setArrowIndex(index);
            setArrowClick(true);
        } else if (arrowIndex !== index) {
            setArrowIndex(index);
            setArrowClick(true);
        } else {
            setArrowIndex();
            setArrowClick(false);
        };
    };
    const fetchBlog = async () => {
        try {
            const { data } = await axios.get('https://icefaucet.com/api/help/blog/');
            setInfoBlog(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (infoBlog === undefined && !error) {
            fetchBlog();
        };
    });
    return (
        <>
            <CategoryWrap>
                <TitleWrap>
                    <p>Press on the following categories to see the articles:</p>
                </TitleWrap>
                {BlogCategories.map((item, index) => (
                    <BlogWrap key={index}>
                        <BlogCategoryWrap onClick={() => handleArrowClick(item.name, index)} primary={index % 2 !== 0 ? 1 : 0}>
                            <BlogCategory>
                                <h4>{item.value}</h4>
                            </BlogCategory>
                            <IconWrap>
                                {index === arrowIndex && arrowClick ? <UpIcon /> : <DownIcon />}
                            </IconWrap>
                        </BlogCategoryWrap>
                        {infoBlog !== undefined && infoBlog.map((blog, indexVideo) => (
                            blog.category === categoryBlog
                                ? <BlogCard key={indexVideo} primary={index % 2 !== 0 ? 1 : 0} active={arrowClick && arrowIndex === index ? 1 : 0}>
                                    <BlogTitleWrap primary={index % 2 !== 0 ? 1 : 0}>
                                        <h4>{blog.title}</h4>
                                    </BlogTitleWrap>
                                    <BlogContentWrap>
                                        <ImgWrap primary={index % 2 !== 0 ? 1 : 0}>
                                            <img src={blog.cover} alt={blog.title} />
                                        </ImgWrap>
                                        <ContentWrap>
                                            <DiscWrap primary={index % 2 !== 0 ? 1 : 0}>
                                                <p>{blog.abstract}</p>
                                            </DiscWrap>
                                            <TinyButton to={location.pathname === '/help'
                                                ? `help/blog/${blog.url_title}`
                                                : location.pathname === `/info/ref/${params.code}/help`
                                                    ? `/help/blog/${blog.url_title}`
                                                    : '#'
                                            }
                                                primary={index % 2 !== 0 ? 1 : 0}
                                                big={0}
                                                style={{ width: '80%' }}
                                            >
                                                read
                                            </TinyButton>
                                        </ContentWrap>
                                    </BlogContentWrap>
                                </BlogCard>
                                : null
                        ))}
                    </BlogWrap>
                ))}
            </CategoryWrap>
            {authorized ? <Ticket /> :
                <MsgWrap primary={0} style={{ marginTop: '25px' }}>
                    <MsgText primary={0}>
                        You must be logged in to send us tickets. If you need help, contact us at marketing@icefaucet.com.
                    </MsgText>
                </MsgWrap>
            }
            {error &&
                <MsgWrap primary={0}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}

export default Blog;
