export const promotionData = [
    'Referral Links',
    'Banners',
    'Social'
];
export const promotionRefTable = [
    {
	detail: 'PURCHASE ADS'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral purchases 1000 AdsCoin, so you earn 500 PrmeCoin (PC) immediately.',
    },
    {
	detail: 'OFFER'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral earns 5000 PC, you earn 250 PC immediately.',
    },
    {
	detail: 'TASK'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral earns 5000 PC, you earn 250 PC immediately.',
    },
    {
	detail: 'FAUCET'
    },
    {
	detail: '2.5 PC'
    },
    {
	detail: 'If your referral claims, you earn 2.5 PC immediately.',
    },
    {
	detail: 'VIEW ADS | SHORTLINK'
    },
    {
	detail: '%10'
    },
    {
	detail: 'If your referral earns 50 PC, you earn 5 PC immediately.',
    },
    {
	detail: 'VIEW ADS'
    },
    {
	detail: '%10'
    },
    {
	detail: 'If your referral views an advertisement and earns 15 PC, you earn 1.5 PC immediately.',
    },
    {
	detail: 'SOCIAL MARKETING'
    },
    {
	detail: '%10'
    },
    {
	detail: 'If your referral earns 500 PC, you earn 50 PC immediately.',
    }
];
export const promotionLinksTable = [
    {
        src: require('../images/home.png').default,
        alt: 'home page',
        client: 'both'
    }
];
