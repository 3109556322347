import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import BlogArticle from '../components/HelpDesk/BlogArticle';
import { Container, Wrapper, DayIcon, NightIcon, UpIcon } from '../components/Article/ArticleElements';
import { animateScroll as scroll } from 'react-scroll';

const HelpBlogPage = () => {
    const params = useParams();
    const title = params.title;
    const [infoArticle, setInfoArticle] = useState();
    const [error, setError] = useState('');
    const [smallScreenSize, setSmallScreenSize] = useState(false);
    const [dark, setDark] = useState(localStorage.getItem('lightness') ? true : false);
    const [showUpIcon, setShowUpIcon] = useState(false);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 480) {
	    setSmallScreenSize(true);
	} else {
	    setSmallScreenSize(false);
	};
    };
    const showUpIconHandler = () => {
	if (window.scrollY > 200) {
	    setShowUpIcon(true);
	} else {
	    setShowUpIcon(false);
	};
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    useEffect(() => {
	window.addEventListener('scroll', showUpIconHandler);
    });
    const lightnessClick = (status) => {
        if (status === 'dark') {
	    localStorage.setItem('lightness', 'dark');
	    setDark(true);
	} else {
	    localStorage.removeItem('lightness');
	    setDark(false);
	};
    };
    const fetchBlogArticle = async () => {
        try {
            const { data } = await axios.get(`https://icefaucet.com/api/help/blog/${title}/`);
            setInfoArticle(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (infoArticle === undefined && !error) {
            fetchBlogArticle();
        };
    });
    return (
        <Container dark={dark}>
	    <Wrapper>
	        <Helmet>
	            <title>iceFaucet | help desk</title>
	        </Helmet>
	        {showUpIcon ? <UpIcon onClick={() => scroll.scrollToTop()} /> : null}
	        {dark ? <NightIcon title='dark' onClick={() => lightnessClick('light')} /> : <DayIcon title='light' onClick={() => lightnessClick('dark')} />}
	        {infoArticle !== undefined && !error &&
		    <BlogArticle data={infoArticle} small={smallScreenSize} dark={dark ? 1 : 0} />
		}
	    </Wrapper>
	</Container>
    )
}
export default HelpBlogPage;
