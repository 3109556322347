import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowUp } from 'react-icons/io';
import { Link as LinkS } from 'react-scroll';
import { FaSun, FaMoon } from 'react-icons/fa';

export const Icons = css`
    position: fixed;
    z-index: 9;
    right: 2%;
    bottom: 10px;
    color: #fff;
    transition: 0.3s ease-out;
    background: rgba(29, 107, 187, 0.8);
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    &:hover {
	color: #01DBBB;
	transition: 0.3s ease-out;
    }
`
export const DayIcon = styled(FaSun)`
    ${Icons}
    font-size: 1.15rem;
`
export const NightIcon = styled(FaMoon)`
    ${Icons}
    font-size: 0.97rem;
`
export const UpIcon = styled(IoIosArrowUp)`
    ${Icons}
    font-size: 2rem;
    bottom: 45px;
    padding: 1px;
`
export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
`
export const Wrapper = styled.div`
    width: 100%;
    max-width: 96vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
`
export const SubjectWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    h1 {
	color: ${({ dark }) => (dark ? 'rgba(1, 219, 187, 0.8)' : 'rgba(29, 107, 187, 0.8)')};
	text-shadow: 0 0 1px ${({ dark }) => (dark ? 'rgba(1, 219, 187, 0.5)' : 'rgba(0, 0, 0, 0.6)')};
	font-size: clamp(0.85rem, 2.8vw, 1.2rem);
	letter-spacing: 1.2px;
	word-spacing: 1.5px;
	text-transform: uppercase;
    }
`
export const EditWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 2px 0 0 10px;
    h3 {
	color: #f9f9f9;
	opacity: 0.65;
	font-size: clamp(0.65rem, 2.8vw, 0.8rem);
	letter-spacing: 0.5px;
	word-spacing: 0.9px;
	margin-right: 4px;
    }
`
export const HeaderContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 15px;
    @media screen and ${device.md} {
	grid-template-columns: 1fr;
	grid-row-gap: 15px;
    }
`
export const HeaderContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const HeaderArticle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(29, 107, 187, 0.8);
    border-radius: 10px 10px 0 0;
    padding: 10px;
`
export const IconWrapStyle = css`
    width: 40px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid rgba(1, 219, 187, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: auto 5px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    @media screen and ${device.sm} {
	width: 30px;
	height: 45px;
    }
    &:hover {
	background: rgba(1, 219, 187, 0.5);
	transition: all 0.2s ease-in-out;  
    }
`
export const BackArticle = styled.a`
    ${IconWrapStyle}
`
export const NextArticle = styled.a`
    ${IconWrapStyle}
`
export const IconStyled = css`
    font-size: clamp(1.6rem, 2.8vw, 2.2rem);
    color: #fff;
`
export const BackIcon = styled(IoIosArrowBack)`
    ${IconStyled}
`
export const NextIcon = styled(IoIosArrowForward)`
    ${IconStyled}
`
export const SectionWrap = styled.div`
    border: 1px solid rgba(29, 107, 187, 0.6);
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    @media screen and ${device.md} {
	grid-template-columns: 1fr;
	grid-row-gap: 15px;
    };
`
export const ImgArticleWrap = styled.div`
    max-width: 600px;
    max-height: 400px;
    border-radius: 10px 0 0 0;
    color: #fff;
    font-size: 1rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
	width: 100%;
	height: 100%;
	margin: auto;
	border-radius: 10px 0 0 0;
    }
    `
export const VideoLinkWrap = styled.div`
    padding: 2px 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
	background: rgba(29, 107, 187, 0.5);
	padding: 2px;
	width: 35px;
	height: 35px;
	margin-right: 10px;
	border-radius: 50%;
    }
`
export const DescWrap = styled.div`
    padding: 15px;
    display: flex;
    align-items: center;
    justfy-content: center;
    p {
	color: ${({ dark }) => (dark ? '#f9f9f9' : '#111121')};
	text-shdow: 0 0 1px rgba(0, 0, 0, 0.3);
	font-size: clamp(0.75rem, 2.8vw, 0.95rem);
	font-weight: bold;
	letter-spacing: 1px;
	word-spacing: 1.2px;
	line-height: 1.8;
    };
`
export const ArticleListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(29, 107, 187, 0.6);
    border-radius: 10px;
    padding: 0 10px;
    margin: 10px auto;
`
export const ArticleListHeader = styled.div`
    position: relative;
    top: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 150px;
    height: 25px;
    background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    border-radius: 35%;
    h2 {
	color: rgba(1, 219, 187, 1);
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	font-size: clamp(0.75rem, 2.8vw, 0.9rem);
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
	text-transform: uppercase;
    }
`
export const ListContainer = styled.ul`
    width: 100%;
    display: flex;
    list-style: none;
    align-items: center;
    border-bottom: 1px solid ${({ dark }) => (dark ? '#f9f9f9' : '#111121')};
    margin-bottom: 5px;
    li {
	width: 100%;
	padding: 3px;
	&:hover {
	    background: rgba(29, 107, 187, 0.3);
	}
    }
`
export const ListNameLink = styled(LinkS)`
    color: ${({ dark }) => (dark ? '#f9f9f9' : '#111121')};
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    font-size: clamp(0.75rem, 2.8vw, 0.9rem);
    font-weight: bold;
    letter-spacing: 1.2px;
    text-shadow:  0 0 1px #000;
`
export const SectionContainer = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : ' rgba(39, 117, 85, 0.3)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    @media screen and ${device.md} {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 15px;
	padding: 5px;
    };
`
export const SectionTitle = styled.div`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    margin-bottom: 15px;
    h2 {
	color: rgb(1, 219, 187);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.95rem, 2.8vw, 1.25rem);
	font-weight: 400;
	letter-spacing: 1.2px;
	word-spacing: 1.7px;
	text-transform: uppercase;
	text-align: center;
    }
`
export const SectionContentWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    @media screen and ${device.md} {
	grid-template-columns: 1fr;
	grid-row-gap: 15px;
	padding: 5px;
    };
`
export const SectionWrapper = styled.div`
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justfy-content: center;
`
export const ImgSectionWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    max-width: 490px;
    max-height: 320px;
    border-radius: ${({ primary }) => (primary ? '30px 0 30px 0' : ' 0 30px 0 30px')};
    color: #fff;
    font-size: 0.9rem;
    text-align: center;
    margin: auto;
    img {
	width: 98%;
	height: 98%;
	border-radius: ${({ primary }) => (primary ? '30px 0 30px 0' : ' 0 30px 0 30px')};
	margin: auto;
    }
`
export const DescSectionWrap = styled.div`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    border-radius: 5px;
    max-width: 490px;
    max-height: 400px;
    padding: 15px;
    margin: 15px auto;
    overflow: auto;
    p {
	color: #fff;
	text-shdow: 0 0 1px rgba(0, 0, 0, 0.3);
	font-size: clamp(0.75rem, 2.8vw, 0.95rem);
	letter-spacing: 1px;
	word-spacing: 1.2px;
	line-height: 1.8;
    };
    &::-webkit-scrollbar {
	width: 9px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    }
`
export const TimerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    border-radius: 15px;
    margin-bottom: 10px;
    @media screen and ${device.sm} {
	flex-direction: column;
	align-items: center;
	justify-content: center;
    };
`
export const TextWrap = styled.div`
    width: 70%;
    background: rgba(39, 117, 85, 0.4);
    margin: auto;
    padding: 5px;
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.8rem, 2.8vw, 1rem);
    letter-spacing: 0.3px;
    word-spacing: 0.7px;
    text-align: center;
    @media screen and ${device.sm} {
	width: 95%;
	margin-bottom: 5px;
    };
`
export const TimerWrap = styled.div`
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.4);
    border: 1px solid rgba(39, 117, 85, 0.6);
    border-radius: 50%;
    @media screen and ${device.sm} {
	padding: 5px;
	height: 40px;
    };
`
export const Timer = styled.h4`
    color: rgb(1, 219, 187);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.75rem, 2.8vw, 0.9rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
    text-transform: uppercase;
    text-align: center;
`
