import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { SocialContainer, SocialWrap, HeaderWrap, LeftWrap, SmallLeftWrap, ItemWrap, SmallItemWrap, ProfileWrap, ProfileImgWrap, UsernameWrap, GiftCodeWrap, SmallGiftCodeWrap, SocialLogo, ProgressBarContainer, ProgressBarWrap, ProgressBar, StuffWrap, PriceWrap, DescriptionWrap, CodeWrap, NumberFormatCode, TimerWrap, Timer } from './RedeemElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import { TinyFormButton } from '../ButtonElements';
import Countdown, { zeroPad } from 'react-countdown';

const Redeem = ({ ads, index }) => {
    const [loading, setLoading] = useState(false);
    const [codeError, setCodeError] = useState('');
    const [complete, setComplete] = useState(false);
    const [giftclick, setGiftClick] = useState();
    const [info, setInfo] = useState();
    const [smallScreenSize, setSmallScreenSize] = useState(false);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 480) {
	    setSmallScreenSize(true);
	} else {
	    setSmallScreenSize(false);
	}
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    const handleGiftClick = (index) => {
	if (index !== giftclick) {
	    setGiftClick(index);
	    complete && setComplete(false);
	    codeError && setCodeError('');
	};
    };
    const handleChangeCode = (e) => {
	let code = e.target.value.replace(/ /g, '').replace('_', '');
	let id = e.target.name;
	setCodeError('');
	if (code.length === 6) {
	    fetchCheckCode(code, id);
	};
    };
    const fetchCheckCode = async (code, id) => {
	setLoading(true);
	try {
	    const { data } = await axiosInstance.post('redeem/match/', {
		'code': parseInt(code),
		'id': id
	    });
	    data !== undefined && setInfo(data);
	    setComplete(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
	};
	setLoading(false);
    };
    const rendererBig = ({ days, hours, minutes, seconds }) => {
	return <Timer>{zeroPad(days)} {zeroPad(days) > 1 ? 'days' : 'day'} + {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    return (
	<>
	    <SocialContainer primary={index % 2 === 0 ? 1 : 0} active={ads.use_code || !ads.visitable ? 0 : 1} cursor={!ads.use_code && !ads.monitoring && ads.visitable ? 1 : 0} onClick={!ads.use_code && !ads.monitoring && ads.visitable ? () => handleGiftClick(index) : null}>
		<SocialWrap primary={index % 2 === 0 ? 1 : 0}>
	    	    <HeaderWrap primary={index % 2 === 0 ? 1 : 0}>
	        	{!smallScreenSize &&
			    <LeftWrap>
			        <ItemWrap primary={index % 2 === 0 ? 1 : 0}>
			            <h3>{ads.category}</h3>
			        </ItemWrap>
			        {parseInt(ads.time_review) > 0 &&
				    <ItemWrap primary={index % 2 === 0 ? 1 : 0}>
					<h3>review</h3>
				    </ItemWrap>
				}
			        {ads.refshow &&
				    <ItemWrap primary={index % 2 === 0 ? 1 : 0}>
					<h3>up-line</h3>
				    </ItemWrap>
				}
			    </LeftWrap>
			}
	        	{smallScreenSize &&
			    <SmallLeftWrap primary={index % 2 === 0 ? 1 : 0}>
				<SmallItemWrap primary={index % 2 === 0 ? 1 : 0}>
				    <h3>{ads.category}</h3>
				</SmallItemWrap>
				{parseInt(ads.time_review) > 0 &&
				    <SmallItemWrap primary={index % 2 === 0 ? 1 : 0}>
					<h3>review</h3>
				    </SmallItemWrap>
				}
			        {ads.refshow &&
				    <SmallItemWrap primary={index % 2 === 0 ? 1 : 0}>
					<h3>up-line</h3>
				    </SmallItemWrap>
				}
			    </SmallLeftWrap>
			}
	        	<ProfileWrap>
	            	    <ProfileImgWrap primary={index % 2 === 0 ? 1 : 0}>
	                	<img src={ads.social_profile} alt='owner' />
	            	    </ProfileImgWrap>
	            	    <UsernameWrap primary={index % 2 === 0 ? 1 : 0}>
	                	<h3>{ads.social_username}</h3>
	            	    </UsernameWrap>
	        	</ProfileWrap>
	    	    </HeaderWrap>
	    	    {!smallScreenSize &&
			<GiftCodeWrap>
			    <SocialLogo src={ads.logo ? ads.logo : ads.logo_url} alt={ads.social} />
			    <ProgressBarContainer>
			        <ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
			            <ProgressBar width={ads.used > 0 ? (String(ads.used / ads.number * 100) + '%') : 0} />
			            <span>{`${ads.used}/${ads.number}`} (Users who have completed this ad.)</span>
			        </ProgressBarWrap>
			        {ads.rate > 10 && parseInt(ads.time_review) > 0 &&
				    <ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
					<ProgressBar width={String(ads.rate) + '%'} />
					<span>{(ads.rate).toFixed(2)}% (Advertiser Approval Rate)</span>
				    </ProgressBarWrap>
				}
			    </ProgressBarContainer>
			    <PriceWrap primary={index % 2 === 0 ? 1 : 0}>
			        <h3>{parseInt(ads.value)} PC</h3>
			    </PriceWrap>
			</GiftCodeWrap>
		    }
	    	    {smallScreenSize &&
			<SmallGiftCodeWrap>
			    <ProgressBarContainer>
				<ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
				    <ProgressBar width={ads.used > 0 ? (String(ads.used / ads.number * 100) + '%') : 0} />
				    <span>{`${ads.used}/${ads.number}`} (Users who have completed this ad.)</span>
				</ProgressBarWrap>
				{ads.rate > 10 && parseInt(ads.time_review) > 0 &&
				    <ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
					<ProgressBar width={String(ads.rate) + '%'} />
					<span>{(ads.rate).toFixed(2)}% (Advertiser Approval Rate)</span>
				    </ProgressBarWrap>
				}
			    </ProgressBarContainer>
			    <StuffWrap>
				<SocialLogo src={ads.logo ? ads.logo : ads.logo_url} alt={ads.title} />
				<PriceWrap primary={index % 2 === 0 ? 1 : 0}>
				    <h3>{parseInt(ads.value)} PC</h3>
				</PriceWrap>
			    </StuffWrap>
			</SmallGiftCodeWrap>
		    }
	    	    {!codeError && !complete && giftclick === index &&
			<>
			    <DescriptionWrap>
			        <p>{ads.desc}</p>
			    </DescriptionWrap>
			    {loading ?
				<Circle style={{ margin: '20px auto', width: '20px', height: '20px' }} /> :
				ads.need_code ? <CodeWrap>
				    <NumberFormatCode
					primary={index % 2 === 0 ? 1 : 0}
					format={"#  #  #  #  #  #"}
					allowEmptyFormatting mask="_ "
					onChange={handleChangeCode}
					name={ads.id}
				    />
				</CodeWrap > :
				    <TinyFormButton style={{ margin: '20px auto' }} primary={index % 2 === 0 ? 1 : 0} big={0} onClick={() => fetchCheckCode(0, ads.id)}>complete</TinyFormButton>
			    }
		        </>
	            }
		    {codeError && !complete && !ads.use_code &&
			<MsgWrap style={{ width: '75%' }} primary={0}>
			    <MsgText primary={0} style={{ fontSize: '0.8rem' }}>
				{codeError}
			    </MsgText>
			</MsgWrap>
		    }
	            {info !== undefined && !info.status &&
			<MsgWrap style={{ width: '75%' }} primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				After the advertiser's confirmed, the amount will be added to your account.
			    </MsgText>
			</MsgWrap>
		    }
	            {ads.monitoring &&
			<MsgWrap style={{ width: '75%' }} primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				After the advertiser's confirmed, the amount will be added to your account.
			    </MsgText>
			</MsgWrap>
		    }
	            {!ads.visitable &&
			<MsgWrap style={{ width: '75%' }} primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				Enter your {ads.social} username and wait until it has been confirmed.
			    </MsgText>
			</MsgWrap>
		    }
	            {complete && info !== undefined && info.status &&
			<MsgWrap style={{ width: '75%' }} primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				{ads.value} PC added to your account successfully.
			    </MsgText>
			</MsgWrap>
		    }
	            {ads.use_code &&
			<MsgWrap style={{ width: '75%' }} primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				You have already completed this ad.
			    </MsgText>
			</MsgWrap>
		    }
	            {info !== undefined && !info.status &&
			<TimerWrap primary={index % 2 === 0 ? 1 : 0}>
			    <h3>max wait for confirmation:</h3>
			    <Countdown date={Date.now() + info.timer * 1000} renderer={info.timer > 24 * 3600 ? rendererBig : renderer} />
			</TimerWrap>
		    }
	            {ads.monitoring &&
			<TimerWrap primary={index % 2 === 0 ? 1 : 0}>
			    <h3>maximum waiting to approve:</h3>
			    <Countdown date={Date.now() + ads.timer * 1000} renderer={ads.timer > 24 * 3600 ? rendererBig : renderer} />
			</TimerWrap>
		    }
	        </SocialWrap>
	    </SocialContainer >
	</>
    )
}
export default Redeem;
