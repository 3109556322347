import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CategoryWrap, TitleWrap, IconWrap, DownIcon, UpIcon } from './FAQElements';
import { PlayListWrap, VideoCategoryWrap, Category, VideoTitleContainer, VideoTitleLinkWrap } from './PlayListElements';
import { MsgWrap, MsgText } from '../MessageElements.js';
import Ticket from '../Ticket';

const PlayList = ({ categoryPlayList, setCategoryPlayList, PlayListCategories }) => {
    const [infoPlayList, setInfoPlayList] = useState();
    const [error, setError] = useState('');
    const [arrowClick, setArrowClick] = useState(false);
    const [arrowIndex, setArrowIndex] = useState();
    const authorized = localStorage.getItem('access_token') ? true : false;
    const handleArrowClick = (categoryTarget, index) => {
        if (categoryTarget && categoryTarget !== categoryPlayList) {
            setCategoryPlayList(categoryTarget);
        };
        if (arrowIndex === undefined) {
            setArrowIndex(index);
            setArrowClick(true);
        } else if (arrowIndex !== index) {
            setArrowIndex(index);
            setArrowClick(true);
        } else {
            setArrowIndex();
            setArrowClick(false);
        };
    };
    const fetchPlayList = async () => {
        try {
            const { data } = await axios.get('https://icefaucet.com/api/help/playlist/');
            setInfoPlayList(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (infoPlayList === undefined && !error) {
            fetchPlayList();
        };
    });
    return (
        <>
            <CategoryWrap>
                <TitleWrap>
                    <p>Press on the following categories to see the playlist:</p>
                </TitleWrap>
                {PlayListCategories.map((item, index) => (
                    <PlayListWrap key={index}>
                        <VideoCategoryWrap onClick={() => handleArrowClick(item.name, index)} primary={index % 2 !== 0 ? 1 : 0}>
                            <Category>
                                <h4>{item.value}</h4>
                            </Category>
                            <IconWrap>
                                {index === arrowIndex && arrowClick ? <UpIcon /> : <DownIcon />}
                            </IconWrap>
                        </VideoCategoryWrap>
                        {infoPlayList !== undefined && infoPlayList.map((video, indexVideo) => (
                            video.category === categoryPlayList
                                ? <VideoTitleContainer key={indexVideo} primary={index % 2 !== 0 ? 1 : 0} active={arrowClick && arrowIndex === index ? 1 : 0}>
                                    <VideoTitleLinkWrap href={video.link} target='_blank' aria-label='Youtube'>
                                        <p>
                                            {video.title}
                                        </p>
                                        <span>{video.seconds} sec</span>
                                    </VideoTitleLinkWrap>
                                </VideoTitleContainer>
                                : null
                        ))}
                    </PlayListWrap>
                ))}
            </CategoryWrap>
            {authorized ? <Ticket /> :
                <MsgWrap primary={0} style={{ marginTop: '25px' }}>
                    <MsgText primary={0}>
                        You must log in to send an email to us.
                    </MsgText>
                </MsgWrap>
            }
            {
                error &&
                <MsgWrap primary={0}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}

export default PlayList;
