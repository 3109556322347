import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, TitleWrap } from './StackElements';
import { RefrigContainer, RefrigCard, TitrWrap, ContentWrap, ItemWrap } from './RefrigratorsElements';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from './RadialSeparators';
import { MsgWrap, MsgText } from '../MessageElements';

const Refrigrators = () => {
    const [info, setInfo] = useState();
    const [error, setError] = useState('');
    const fetchRefrigrators = async () => {
        try {
            const { data } = await axiosInstance.get('interest/refrigrators/');
            setInfo(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        fetchRefrigrators();
    }, []);
    return (
        <StackContainer primary={1}>
            <StackWrap primary={1}>
                <TitleWrap primary={1}>
                    <h2>your refrigerators</h2>
                </TitleWrap>
                {!error &&
                    <RefrigContainer>
                        {info && info.map((refrig, index) => (
                            <RefrigCard key={index}>
                                <TitrWrap style={{ borderRadius: '15px 15px 0 0' }} color={refrig.status}>
                                    <h3>{refrig.time} months</h3>
                                </TitrWrap>
                                <ContentWrap color={refrig.status}>
                                    {refrig.status === 'F' &&
					<>
                                            <ItemWrap>
                                            	<span style={{ marginTop: '30px' }}>freezing proccess:</span>
                                            	<div style={{ width: '80px', height: '80px' }}>
                                                    <CircularProgressbarWithChildren
                                                    	value={refrig.freezing_process}
                                                    	maxValue={100}
                                                    	text={`${refrig.freezing_process}%`}
                                                    	strokeWidth={6}
                                                    	styles={buildStyles({
                                                            strokeLinecap: "butt",
                                                            textColor: 'rgba(1, 219, 187, 0.7)',
                                                            pathColor: 'rgba(1, 219, 187, 0.7)',
                                                            textSize: '1.2rem'
                                                        })}
                                                    >
                                                        <RadialSeparators
                                                            count={3}
                                                            style={{
                                                            	background: "#fff",
                                                            	width: "2px",
                                                            	height: `${6}%`
                                                            }}
                                                    	/>
                                                    </CircularProgressbarWithChildren>
                                            	</div>
                                            </ItemWrap>
					    <ItemWrap>
					        <span>rented at:</span>
					        <span> {refrig.created.replace('-', '/').split('T')[0].replace('-', '/')}</span>
					    </ItemWrap>
					</>
                                    }
                                    {refrig.status === 'W' &&
					<>
                                            <ItemWrap>
                                            	<span style={{ marginTop: '30px' }}>monthy working:</span>
                                            	<div style={{ width: '80px', height: '80px' }}>
                                                    <CircularProgressbarWithChildren
                                                    	value={refrig.monthly_work}
                                                    	maxValue={refrig.time}
                                                    	text={`${refrig.monthly_work}/${refrig.time}`}
                                                    	strokeWidth={6}
                                                    	styles={buildStyles({
                                                            strokeLinecap: "butt",
                                                            textColor: 'rgba(1, 219, 187, 0.7)',
                                                            pathColor: 'rgba(1, 219, 187, 0.7)',
                                                            textSize: '1.2rem'
                                                        })}
                                                    >
                                                        <RadialSeparators
                                                            count={refrig.time}
                                                            style={{
                                                                background: "#fff",
                                                                width: "2px",
                                                                height: `${6}%`
                                                            }}
                                                        />
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                            </ItemWrap>
					    <ItemWrap>
						<span>working at:</span>
						<span>{refrig.start_working_time.replace('-', '/').split('T')[0].replace('-', '/')}</span>
					    </ItemWrap>
					    <ItemWrap>
						<span>paid profit:</span>
						<span>{refrig.total_paid}</span>
					    </ItemWrap>
					</>
                                    }
                                    {refrig.status === 'E' &&
					<>
                                            <ItemWrap>
                                            	<span >received profit:</span>
                                            	<span>{refrig.total_paid}</span>
                                            </ItemWrap>
					    <ItemWrap>
						<span>rented at:</span>
						<span> {refrig.created.replace('-', '/').split('T')[0].replace('-', '/')}</span>
					    </ItemWrap>
					</>
                                    }
                                </ContentWrap>
                                <TitrWrap style={{ borderRadius: '0 0 15px 15px' }} color={refrig.status}>
                                    <h3>{refrig.status === 'W' ? 'working' : refrig.status === 'E' ? 'expired' : 'freezing'}</h3>
                                </TitrWrap>

                            </RefrigCard>
                        ))}
                    </RefrigContainer>
                }
                {error &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {error}
                        </MsgText>
                    </MsgWrap>
                }
            </StackWrap>
        </StackContainer>
    )
}
export default Refrigrators
