import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { creditCheckAction, PtcSetUpAction } from '../../actions/PtcSetUpAction';
import { miningSetUpAction } from '../../actions/miningSetUpAction';
import { bannerSetUpAction } from '../../actions/bannerSetUpAction';
import { sponsorSetUpAction } from '../../actions/sponsorSetUpAction';
import { CreditFormWrap, CreditContentWrap, FormButtonsWrap } from './CreditCheckElements';
import { TinyButton, TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import axiosInstance from '../../axios';

const CreditCheck = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [buttonText, setButtonText] = useState('');
    const type = location.pathname === '/setup-ads/ptc' ? 10
        : location.pathname === '/setup-ads/surf'
            ? 8
            : location.pathname === '/setup-ads/video'
                ? 6
                : location.pathname === '/setup-ads/auto'
                    ? 4
                    : location.pathname === '/setup-ads/banner'
                        ? 1
                        : location.pathname === '/setup-ads/sponsor-link'
                            ? 0.5
                            : null;
    const required = location.pathname === '/setup-ads/banner' || location.pathname === '/setup-ads/sponsor-link' ? type * 10000 : type * 1000;
    const handleClick = () => {
        if (location.pathname === '/setup-ads/ptc'
            || location.pathname === '/setup-ads/surf'
            || location.pathname === '/setup-ads/video') {
            dispatch(creditCheckAction({ prop: false }));
            dispatch(PtcSetUpAction({ prop: true }));
        };
        if (location.pathname === '/setup-ads/auto') {
            dispatch(creditCheckAction({ prop: false }));
            dispatch(miningSetUpAction({ prop: true }));
        };
        if (location.pathname === '/setup-ads/banner') {
            dispatch(creditCheckAction({ prop: false }));
            dispatch(bannerSetUpAction({ prop: true }));
        };
        if (location.pathname === '/setup-ads/sponsor-link') {
            dispatch(creditCheckAction({ prop: false }));
            dispatch(sponsorSetUpAction({ prop: true }));
        };
    };
    const fetchCreditCheck = async () => {
        const { data } = await axiosInstance.get('ptc/advertise/credit-ads-check/');
        localStorage.setItem('adcoin', data.adcoin);
        if (parseInt(data.adcoin) < required) {
            setButtonText('Purchase Adscoin');
        } else if (parseInt(data.adcoin) >= required) {
            setButtonText('Start');
        };
    };
    useEffect(() => {
        if (buttonText.length === 0) fetchCreditCheck();
    });
    return (
        <>
            <CreditFormWrap>
                <CreditContentWrap>
                    <span>your adscoin balance:</span>
                    <span>{localStorage.getItem('adcoin')}</span>
                </CreditContentWrap>
                <CreditContentWrap>
                    <span>required:</span>
                    <span>{required} adcoin</span>
                </CreditContentWrap>
                <CreditContentWrap>
                    <span>{(location.pathname === '/setup-ads/banner' || location.pathname === '/setup-ads/sponsor-link') ? 'cost per impression (CPI)' : 'cost per click (CPC)'}</span>
                    <span>{type} adscoin</span>
                </CreditContentWrap>
            </CreditFormWrap>
            {buttonText === 'Purchase Adscoin' ? <MsgWrap primary={0}><MsgText primary={0}>You did not have enough adscoin</MsgText></MsgWrap> : null}
            <FormButtonsWrap>
                {buttonText === 'Start'
                    ? <TinyFormButton primary={0} big={1} type='button' onClick={handleClick}>
                        {buttonText}
                    </TinyFormButton>
                    : buttonText === 'Purchase Adscoin'
                        ? <TinyButton to='/deposit/adscoin' primary={0}>
                            {buttonText}
                        </TinyButton>
                        : <TinyFormButton type='button' primary={0} big={1} >loading...</TinyFormButton>}
                <TinyButton to={'/setup-ads'} primary={0} big={1}>
                    back
                </TinyButton>
            </FormButtonsWrap>
        </>
    )
};
export default CreditCheck;
