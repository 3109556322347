export const SMData = [
    {
	time: 'no reset',
	name: 'daily turbo',
	max: 100,
	helpmsg: "Your income will double forever if you claim 100 days without interruption!"
    },
    {
	time: 'daily',
	name: 'view ads turbo',
	max: 200,
	helpmsg: '1% is added for watching every "VIEW ADS" such as "SHORTLINK", "PTC", "SURF" and "VIDEOS" and 0.5% is added for playing "AUTO". After 24 hours of watching each ad, the bonus will be reduced.'
    },
    {
	time: 'daily',
	name: 'offer turbo',
	max: 200,
	helpmsg: 'For every 25 primecoins you earn from "OFFER" and "TASK" (offer + task), +1% is added to your claim for 24 hours. After 24 hours of completing each offer or task, the bonus will be reduced.'
    },
];
