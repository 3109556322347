import React, { useEffect, useState } from 'react';
import WheelComponent from './Spin';

const LuckyWheel = ({ info }) => {
    const segments = ["LOTTERY", "THUNDER", "250 PC", "350 PC", "500 PC", "ICEDAY", "1000 PC", "2500 PC"];
    const segColors = [
	"rgba(29, 107, 187, 0.5)",
        "rgba(39, 117, 85, 0.5)",
	"rgba(120, 116, 16, 0.5)",
	"rgba(90, 20, 200, 0.5)",
	"rgba(29, 107, 187, 0.85)",
	"rgba(39, 117, 85, 0.85)",
	"rgba(120, 116, 16, 0.85)",
	"rgba(90, 20, 200, 0.85)",
    ];
    const [small, setSmall] = useState();
    const deviceScreenhandler = () => {
	if (window.innerWidth < 381) {
	    setSmall(true);
	} else {
	    setSmall(false);
	};
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    const onFinished = (winner) => {
    };
    return (
	<>
	    {small !== undefined &&
		<WheelComponent
		    segments={segments}
		    segColors={segColors}
		    onFinished={(winner) => onFinished(winner)}
		    primaryColor="rgba(1, 219, 187, 1)"
	            contrastColor="rgba(29, 107, 187, 1)"
		    size={!small ? 140 : 100}
		    upDuration={200}
		    downDuration={800}
		    info={info}
		    small={small}
		/>
	    }
	</>
    )
}
export default LuckyWheel;
