import styled, { keyframes } from 'styled-components';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';
import { FooterSocialWrap, SocialIconLink } from '../Footer/FooterElements';

export const MBContainer = styled.div`
    position: fixed;
    top: 60px;
    left: ${({ isShow }) => (isShow ? '0' : '-100%')};
    background: #010606;
    width: 33%;
    height: calc(100% - 60px);
    transition: 500ms;
    z-index: 97;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and ${device.xl} {
        left: 0;
        width: 25%;
    }
    @media screen and ${device.md} {
        left: ${({ isShow }) => (isShow ? '0' : '-100%')};
        width: 100%;
    }
`
export const MBWrap = styled.nav`
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: 0 0 3px rgba(29, 107, 187, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.85);
    }

`
export const MBMenu = styled.ul`
    list-style: none;
    margin-right: 2px;
`
export const giftCodeAnimation = keyframes`
    0% { border: 1px solid rgba(1, 219, 187, 1)}
    25% { border: none}
    50% { border: 1px solid rgba(1, 219, 187, 1)}
    0% { border: none}
    100%{ border: 1px solid rgba(1, 219, 187, 1)}
`
export const MenuList = styled.li`
    height: 45px;
    background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : 'rgba(29, 107, 187, 0.5)')};
    border: ${({ giftCode }) => (giftCode ? '1px solid rgba(1, 219, 187, 1)' : 'none')};
    margin: 1.5px 0;
    transition: all 0.2s ease-in-out;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${giftCodeAnimation} 1s linear ${({ giftCode }) => (giftCode ? '8' : '0')} forwards;
    }
    &:hover {
        border-left: ${({ active }) => (active ? 'none' : '5px solid rgb(1, 219, 187)')};
        transition: all 0.2s ease-in-out;
    } 
`
export const MenuLinks = styled(Link)`
    text-decoration: none;
    color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 5px;
    div {
        &:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    span {
        &:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &: nth-child(2) {
            margin: auto 10px;
            font-size: clamp(0.6rem, 1.2vw, 0.75rem);
            font-weight: bold;
            letter-spacing: 1.4px;
            word-spacing: 2px;
            text-transform: uppercase;
        }
    }
`
export const MenuHref = styled.a`
    text-decoration: none;
    color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 5px;
    div {
	display: flex;
	justify-content: center;
	align-items: center;
    }
    span {
	&:nth-child(1) {
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
	&: nth-child(2) {
	    margin: auto 10px;
	    font-size: clamp(0.6rem, 1.2vw, 0.75rem);
	    font-weight: bold;
	    letter-spacing: 1.4px;
	    word-spacing: 2px;
	    text-transform: uppercase;
	}
    }
`
export const DropDownLinks = styled(Link)`
    text-decoration: none;
    color: #f9f9f9;
    font-size: clamp(0.7rem, 1.5vw, 0.85rem);
    letter-spacing: 1px;
    word-spacing: 1.5px;
    text-transform: uppercase;
    display: flex;
    padding: 15px 10px;
    background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : 'transparent')};
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : 'rgba(29, 107, 187, 0.5)')};
        transition: all 0.2s ease-in-out;
    }
`
export const AuthFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    font-size: 0.8rem;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;

    &:hover {
        opacity: 0.75;
        transition: all 0.3s ease-in-out;
    }
`
export const AuthFooterSocialWrap = styled(FooterSocialWrap)`
    margin: 0 0 10px 0;
`
export const AuthSocialIconLink = styled(SocialIconLink)`
    font-size: 1.1rem;
`
export const FaucetTimer = styled.div`
    color: red;
    text-shadow: 0 0 1px rgba(0,0,0,0.6);
    font-size: 0.95rem;
`
