import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';
import { DepositTypeWrap, CryptoWrap } from './DepositElements';
import { WContainer, WWrap, TypeCard, ImageWrap, Image, WContext, WContextItem, LimitWrap, CryptoDeactiveWrap, PromoWrap } from '../Withdraw/WithdrawElements';
import { NumberInputWrap, NumberInput } from '../SetUpPtc/SetUpPtcFilterElements';
import { TinyFormButton, FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import Modal from './Modal';
import InternalModal from './InternalModal';
import moment from 'moment';

const Deposit = ({ info }) => {
    const location = useLocation();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [apiError, setApiError] = useState('');
    const [errors, setErrors] = useState('');
    const [cryptoGatewayInfo, setCryptoGatewayInfo] = useState();
    const [userIcecoin, setUserIcecoin] = useState(0);
    const [userPcoin, setUserPcoin] = useState(0);
    const [limitationTime, setLimitationTime] = useState();
    const [cryptoSelectedInfo, setCryptoSelectedInfo] = useState(Object.freeze());
    const [cryptoIndex, setCryptoIndex] = useState();
    const [value, setValue] = useState(Object.freeze(''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timer, setTimer] = useState();
    const [payeerInfo, setPayeerInfo] = useState();
    const [customFP, setCustomFP] = useState('');
    const [fetchSignature, setFetchSignature] = useState(false);
    const [fetchCustom, setFetchCustom] = useState(false);
    const coinName = location.pathname === '/deposit/pcoin' ? 'primecoins' : 'adscoins';
    const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
    const handleClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setTypeIndex(index);
	    limitationTime !== undefined && setLimitationTime();
            fetchLimitationCheck(typeTarget);
            setErrors('');
            setApiError('');
            setCryptoSelectedInfo();
            setCryptoIndex();
            setIsSubmitting(false);
        };
    };
    const fetchLimitationCheck = async (typeTarget) => {
	const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
	try {
	    const { data } = await axiosInstance.get(`financial/cash-in/check/${typeTarget}/${coin}/`);
	    if (data.status) {
		setCryptoGatewayInfo(data.info);
		setUserIcecoin(data.user_icecoin);
		setUserPcoin(data.user_pcoin);
	    } else {
		setLimitationTime(data.detail);
	    };
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const selectCrypto = (index, crypto) => {
	if (crypto.active_deposit) {
            setCryptoSelectedInfo(crypto);
	    setCryptoIndex(index);
	    setValue(crypto.min_deposit * 10000);
            setIsSubmitting(false);
	};
    };
    const handleChange = (e) => {
        let number = parseInt(e.target.value);
        if (!isNaN(number)) {
            setValue(parseInt(number));
        };
        setErrors('');
        setIsSubmitting(false);
    };
    const handleMax = () => {
	if (type === 'I' && cryptoSelectedInfo.name === 'icecoin') {
	    setValue(parseInt(userIcecoin * 10));
	} else if (type === 'I' && cryptoSelectedInfo.name === 'primecoin') {
	    setValue(parseInt(userPcoin));
	} else {
	    setValue(9990000);
	};
    };
    
    const handleSubmit = () => {
        if (value < cryptoSelectedInfo.min_deposit * 10000) {
	    setErrors(`The minimum deposit is ${cryptoSelectedInfo.min_deposit * 10000} ${coinName}.`);
	} else if (value > 9990000) {
	    setErrors(`The maximum withdrawal is ${9990000} ${coinName}.`);
	} else if (type === 'I' && cryptoSelectedInfo.name === 'icecoin' && value > userIcecoin * 10) {
	    setErrors('You do not have enough icecoins!');
	} else if (type === 'I' && cryptoSelectedInfo.name === 'primecoin' && value > userPcoin) {
	    setErrors('You do not have enough primecoins!');
	} else {
	    valueSave();
	};
    };
    const valueSave = async () => {
	try {
	    await axiosInstance.post('financial/cash-in/auto/set-value/', { 'value': value });
	    type === 'M' && setTimer(Date.now() + 20 * 60 * 1000);
	    type === 'I' && setTimer(Date.now() + 5 * 60 * 1000);
	    (type === 'M' || type === 'I') && setIsSubmitting(true);
	    type === 'P' && cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !fetchSignature && !payeerInfo && apiError.length === 0 && fetchPayeerSignature();
	    type === 'F' && cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !fetchCustom && customFP.length === 0 && apiError.length === 0 && fetchFPCustom();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const fetchPayeerSignature = async () => {
	setFetchSignature(true);
	try {
	    const { data } = await axiosInstance.post('financial/cash-in/payeer/signature/', { 'coin': coin, 'value': value });
	    setPayeerInfo(data);
	    const form = document.createElement('form');
	    form.action = 'https://payeer.com/merchant/';
	    form.target = '_blank';
	    form.method = 'post';
	    document.body.appendChild(form);
	    const input1 = document.createElement('input');
	    input1.type = 'hidden';
	    input1.name = 'm_shop';
	    input1.value = data.m_shop;
	    form.appendChild(input1);
	    const input2 = document.createElement('input');
	    input2.type = 'hidden';
	    input2.name = 'm_orderid';
	    input2.value = data.m_orderid;
	    form.appendChild(input2);
	    const input3 = document.createElement('input');
	    input3.type = 'hidden';
	    input3.name = 'm_amount';
	    input3.value = (value / 10000).toFixed(2);
	    form.appendChild(input3);
	    const input4 = document.createElement('input');
	    input4.type = 'hidden';
	    input4.name = 'm_curr';
	    input4.value = 'USD';
	    form.appendChild(input4);
	    const input5 = document.createElement('input');
	    input5.type = 'hidden';
	    input5.name = 'm_desc';
	    input5.value = data.m_desc;
	    form.appendChild(input5);
	    const input6 = document.createElement('input');
	    input6.type = 'hidden';
	    input6.name = 'm_sign';
	    input6.value = data.signature;
	    form.appendChild(input6);
	    const input7 = document.createElement('input');
	    input7.type = 'hidden';
	    input7.name = 'form[ps]';
	    input7.value = '2609';
	    form.appendChild(input7);
	    const input8 = document.createElement('input');
	    input8.type = 'hidden';
	    input8.name = 'form[curr[2609]]';
	    input8.value = cryptoSelectedInfo.abbreviation;
	    form.appendChild(input8);
	    form.submit();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const fetchFPCustom = async () => {
	setFetchCustom(true);
	try {
	    const { data } = await axiosInstance.get('financial/cash-in/faucetpay/custom/');
	    setCustomFP(data.custom);
	    const form = document.createElement('form');
	    form.action = 'https://faucetpay.io/merchant/webscr';
	    form.target = '_blank';
	    form.method = 'post';
	    document.body.appendChild(form);
	    const input1 = document.createElement('input');
	    input1.type = 'hidden';
	    input1.name = 'merchant_username';
	    input1.value = 'icefa';
	    form.appendChild(input1);
	    const input2 = document.createElement('input');
	    input2.type = 'hidden';
	    input2.name = 'item_description';
	    input2.value = 'deposit to iceFaucet';
	    form.appendChild(input2);
	    const input3 = document.createElement('input');
	    input3.type = 'hidden';
	    input3.name = 'amount1';
	    input3.value = (value / 10000);
	    form.appendChild(input3);
	    const input4 = document.createElement('input');
	    input4.type = 'hidden';
	    input4.name = 'currency1';
	    input4.value = 'USD';
	    form.appendChild(input4);
	    const input5 = document.createElement('input');
	    input5.type = 'hidden';
	    input5.name = 'currency2';
	    input5.value = cryptoSelectedInfo.abbreviation;
	    form.appendChild(input5);
	    const input6 = document.createElement('input');
	    input6.type = 'hidden';
	    input6.name = 'custom';
	    input6.value = data.custom;
	    form.appendChild(input6);
	    const input7 = document.createElement('input');
	    input7.type = 'hidden';
	    input7.name = 'callback_url';
	    input7.value = `https://icefaucet.com/api/financial/cash-in/faucetpay/callback/${coin}/`;
	    form.appendChild(input7);
	    const input8 = document.createElement('input');
	    input8.type = 'hidden';
	    input8.name = 'success_url';
	    input8.value = 'https://icefaucet.com/faucetpay/deposit/success';
	    form.appendChild(input8);
	    const input9 = document.createElement('input');
	    input9.type = 'hidden';
	    input9.name = 'cancle_url';
	    input9.value = 'https://icefaucet.com/faucetpay/deposit/fail';
	    form.appendChild(input9);
	    form.submit();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    return (
        <WContainer>
            <WWrap>
                <DepositTypeWrap internal={coin === 'A' ? 1 : 0}>
                    {info.map((item, index) => (
			item.type === 'I' && coin === 'P' ? null :
                            <TypeCard key={index} primary={1} active={index === typeIndex ? 1 : 0} cryptoDeactive={0}>
                            	<ImageWrap primary={0}>
                                    <Image src={item.src} alt={item.alt} title={item.title} />
                            	</ImageWrap>
                                <WContext>
                                    <WContextItem>
                                    	<span>Type:</span>
                                    	<span>{item.name}</span>
                                    </WContextItem>
                                    <WContextItem>
                                    	<span>max deposit:</span>
                                    	<span>{item.max}</span>
                                    </WContextItem>
                                    <WContextItem>
                                    	<span>limitation:</span>
                                    	<span>{item.limitation}</span>
                                    </WContextItem>
                            	</WContext>
                                <TinyFormButton type='button' name={item.type} primary={index === typeIndex ? 1 : 0} onClick={(e) => handleClick(e, index)}>
                                    select
                            	</TinyFormButton>
                            </TypeCard>
                   ))}
                </DepositTypeWrap>
                {cryptoGatewayInfo && Object.keys(cryptoGatewayInfo).length > 0 &&
                    <CryptoWrap twoColumn={type === 'F' || type === 'I' ? 1 : 0}>
                        {cryptoGatewayInfo.map((crypto, index) => (
			    <TypeCard key={index} style={{ width: '105px', height: '145px', padding: '7px 0 0 0' }} primary={0} active={cryptoIndex === index ? 1 : 0} cryptoDeactive={!crypto.active_deposit ? 1 : 0}>
				{((coin === 'P' && crypto.promo_pcoin > 0) || (coin === 'A' && crypto.promo_adscoin > 0)) &&
				    <PromoWrap>
					<h4>{coin === 'P' ? crypto.promo_pcoin : crypto.promo_adscoin}%</h4>
				    </PromoWrap>
				}
				<ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
				    <Image src={crypto.image} alt={crypto.name} />
				    {!crypto.active_deposit &&
					<CryptoDeactiveWrap>
					    <h4>{crypto.reason_deactive_deposit}</h4>
					</CryptoDeactiveWrap>
				    }
				</ImageWrap>
				<TinyFormButton style={{ padding: '3px 7px' }} primary={cryptoIndex === index ? 1 : 0} onClick={() => selectCrypto(index, crypto)}>
				    {crypto.name}
				</TinyFormButton>
				{crypto.network &&
				    <LimitWrap>
					<h4>netwotk:</h4>
					<h4>{crypto.network}</h4>
				    </LimitWrap>
				}
				<LimitWrap>
				    <h4>min deposit:</h4>
				    <h4>${crypto.min_deposit}</h4>
				</LimitWrap>
			    </TypeCard>
			))}
                    </CryptoWrap>
                }
                {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 &&
                    <NumberInputWrap>
                        <NumberInput
                            type='number'
                            value={value}
                            onChange={handleChange}
                            min={cryptoSelectedInfo.min_deposit * 10000}
                            max={9990000}
                            step={100}
                        />
                        <TinyFormButton type='button' onClick={() => handleMax()} primary={0} big={0} >
                            max
                        </TinyFormButton>
                    </NumberInputWrap>
                }
                {errors &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                }
                {apiError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
                {limitationTime &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            You could deposit again {moment(Date.now() + limitationTime * 1000).fromNow()}.
                        </MsgText>
                    </MsgWrap>
                }
                {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !isSubmitting && !apiError && !limitationTime && !errors && (type === 'M' || type === 'I') &&
                    <FormButton
                        type='button'
                        style={{ width: '80%', maxWidth: '500px', marginBottom: '15px' }}
                        big={0}
                        primary={1}
                        onClick={() => handleSubmit()}
                    >
                        Deposit
                    </FormButton>
                }
	        {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && type === 'F' && !payeerInfo &&
		    <FormButton type="submit" name="submit" style={{ width: '100%', maxWidth: '500px', marginBottom: '15px' }} big={0} primary={1} onClick={() => handleSubmit()} > Deposit FP</FormButton>
		}
		{cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && type === 'P' && !payeerInfo &&
		    <FormButton type="submit" style={{ width: '100%', maxWidth: '500px', marginBottom: '15px' }} big={0} primary={1} onClick={() => handleSubmit()} > Deposit payeer</FormButton>
		}
		{cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !apiError && !errors && type === 'M' && timer !== undefined &&
		    <Modal crypto={cryptoSelectedInfo.abbreviation} cryptoName={cryptoSelectedInfo.name} address={cryptoSelectedInfo.deposit_address} value={value} timer={timer} setTimer={setTimer} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
		}
		{cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !apiError && !errors && type === 'I' && timer !== undefined &&
		    <InternalModal crypto={cryptoSelectedInfo.abbreviation} cryptoName={cryptoSelectedInfo.name} value={value} timer={timer} setTimer={setTimer} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} price={cryptoSelectedInfo.name === 'icecoin' ? 0.001 : 0.0001} />
		}
            </WWrap>
        </WContainer >
    )
}
export default Deposit;
