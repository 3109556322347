import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { userSocialMedia } from '../../data/userSocialData';
import { UserSocialContainer, UserSocialWrap, UserSocialHeader, UserSocialMainWrap, MediaContainer, MediaWrapper, MediaWrap, MediaImgWrap, MediaImg, InputWrap, Input, StatusWrap } from './UserSocialElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import { TinyFormButton } from '../ButtonElements';

const UserSocial = ({ info }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [complete, setComplete] = useState(false);
    const [uniqueError, setUniqueError] = useState({
	instagram: '',
	x: '',
	youtube: ''
    });
    const [apiError, setApiError] = useState('');
    const [values, setValues] = useState(Object.freeze({
	instagram: info.find((media) => media.social === 'instagram') ? info.find((media) => media.social === 'instagram').username : '',
	x: info.find((media) => media.social === 'x') ? info.find((media) => media.social === 'x').username : '',
	youtube: info.find((media) => media.social === 'youtube') ? info.find((media) => media.social === 'youtube').username : ''
    }));
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
	    ...values,
	    [name]: value,
	});
	setError('');
	setUniqueError({
	    ...uniqueError,
	    [name]: '',
	});
    };
    const handleBlur = (e) => {
	const { name, value } = e.target;
	value.length > 0 && fetchUniqueUsernameStatus(name, value);
    };
    const fetchUniqueUsernameStatus = async (social, username) => {
	try {
	    await axiosInstance.post('redeem/user-social/unique/', {
		'social': social,
		'username': username
	    });
	} catch (error) {
	    error.response && error.response.data.detail ? setUniqueError({ ...uniqueError, [social]: error.response.data.detail }) : setUniqueError({ ...uniqueError, [social]: error.message });
	};
    };
    const handleSubmit = (e) => {
	e.preventDefault();
	values.instagram.length === 0 && values.x.length === 0 && values.youtube.length === 0 ?
	    setError('Enter the username of your social media for at least one of the fields!') :
	    values.instagram.length > 0 && values.instagram[0] === '@' ?
		setError('Instagram username does not have @.') :
		values.x.length > 0 && values.x[0] !== '@' ?
		    setError('X username starts with @.') :
		    values.youtube.length > 0 && values.youtube[0] !== '@' ?
		        setError('Youtube username starts with @.') :
		        fetchSubmitSocial();
    };
    const fetchSubmitSocial = async () => {
	setLoading(true);
	try {
	    await axiosInstance.post('redeem/user-social/record/', {
		'instagram': values.instagram,
		'x': values.x,
		'youtube': values.youtube
	    });
	    setComplete(true);
	    setTimeout(() => {
		window.location.reload(false);
	    }, 2000);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    return (
	<UserSocialContainer>
	    <UserSocialWrap>
	        <UserSocialHeader>
	            <h2>Your Social Media</h2>
	        </UserSocialHeader>
	        <UserSocialMainWrap action='#' onSubmit={!loading ? handleSubmit : null}>
	            {userSocialMedia.map((social, index) => (
			<MediaContainer key={index}>
			    <MediaWrapper>
			        <MediaWrap>
			            <MediaImgWrap>
			                <MediaImg
			                    src={social.src}
			                    alt={social.social}
			                />
			            </MediaImgWrap>
			            <InputWrap>
			                <Input
			                    style={{ paddingLeft: '15px' }}
			                    type='text'
			                    name={social.social}
			                    primary={0}
			                    placeholder={`Enter your ${social.social} username.`}
			                    onChange={handleChange}
			                    disabled={info.find((media) => media.social === social.social)}
			                    value={values[`${social.social}`]}
			                    onBlur={handleBlur}
			                >
			                </Input>
			            </InputWrap>
			            <StatusWrap>
			                <h3>{info.find((media) => media.social === social.social) ? info.find((media) => media.social === social.social).m_status : '1 spin'}</h3>
			            </StatusWrap>
			        </MediaWrap>
			    </MediaWrapper>
			    {uniqueError[`${social.social}`] &&
				<MsgWrap primary={0}>
				    <MsgText primary={0} style={{ fontSize: '0.8rem' }}>
					{uniqueError[`${social.social}`]}
				    </MsgText>
				</MsgWrap>
			    }
			    {info.find((media) => media.social === social.social) && info.find((media) => media.social === social.social).m_status === 'Pending' &&
				<MsgWrap primary={1}>
				    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
					Please wait for confirmation.
				    </MsgText>
				</MsgWrap>
			    }
			    {info.find((media) => media.social === social.social) && info.find((media) => media.social === social.social).m_status === 'Decline' &&
				<MsgWrap primary={0}>
				    <MsgText primary={0} style={{ fontSize: '0.8rem' }}>
					Unfortunately, this social media was not confirmed.
				    </MsgText>
				</MsgWrap>
			    }
			</MediaContainer>
		    ))}
	    	    {error && !apiError && !complete &&
			<MsgWrap primary={0}>
			    <MsgText primary={0} style={{ fontSize: '0.8rem' }}>
				{error}
			    </MsgText>
			</MsgWrap>
		    }
	            {apiError && !complete &&
			<MsgWrap primary={0}>
			    <MsgText primary={0} style={{ fontSize: '0.8rem' }}>
				{apiError}
			    </MsgText>
			</MsgWrap>
		    }
	            {!error && !apiError && complete &&
		        <MsgWrap primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				Your request was successfully submitted.
		    	    </MsgText>
			</MsgWrap>
		    }
	            {!error && !apiError && !complete && Object.keys(info).length < 3 &&
			<TinyFormButton big={0}>
			    {loading ? <Circle style={{ width: '20px', height: '20px' }} /> : 'submit'}
			</TinyFormButton>
		    }
	            {Object.keys(info).length >= 3 && !info.find((media) => media.m_status !== 'Confirmed') &&
			<MsgWrap primary={1}>
			    <MsgText primary={1} style={{ fontSize: '0.8rem' }}>
				Your social media is successfully confirmed. If you want to change them contact us.
			    </MsgText>
			</MsgWrap>
		    }
	        </UserSocialMainWrap>
	    </UserSocialWrap>
	</UserSocialContainer >
    )
}
export default UserSocial;
