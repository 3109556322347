import React from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterWrap, FooterNav, FooterLogoContainer, FooterLogoWrap, FooterLogoLink, FooterLogo, FooterSocialWrap, SocialIconLink, Youtube, Instagram, Twitter, FooterContact, TitleContact, DescContact, FooterLinkItems, FooterLinkTitle, FooterLink, WebsiteRightsWrap, WebsiteRights } from './FooterElements';
import { footerData, footerLangData, footerRightsData, footerRightsLangData } from '../../data/footerData';
import logo from '../../images/logo-footer.png';

const Footer = ({ code }) => {
    const location = useLocation();
    const pageLang = localStorage.getItem('pageLang');
    const footerDataLang = pageLang ? footerLangData.find(data => data.lang === pageLang).info : footerData;
    const footerRightsDataLang = pageLang ? footerRightsLangData.find(data => data.lang === pageLang).info : footerRightsData;
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterNav>
                    <FooterLogoContainer>
                        <FooterLogoWrap>
                            <FooterLogoLink
                                to={location.pathname === '/' || location.pathname === '/advertise' || location.pathname === '/make-money' || location.pathname === '/statistics'
                                    ? '/'
                                    : location.pathname === `/ref/${code}` || location.pathname === `/ref/${code}/advertise` || location.pathname === `/ref/${code}/make-money` || location.pathname === `/ref/${code}/statistics`
                                        ? `/ref/${code}`
                                        : '#'
                                }
                                onClick={() => scroll.scrollToTop()}
                            >
                                <FooterLogo src={logo} alt={'logo of icefaucet'} />
                            </FooterLogoLink>
                        </FooterLogoWrap>
                        <FooterSocialWrap>
                            <SocialIconLink href='https://youtube.com/@icefaucet' target='_blank' aria-label='Youtube'>
                                <Youtube />
                            </SocialIconLink>
                            <SocialIconLink href='https://instagram.com/icefa.social' target='_blank' aria-label='Instagram'>
                                <Instagram />
                            </SocialIconLink>
                            <SocialIconLink href='https://twitter.com/icefa_social' target='_blank' aria-label='Twitter'>
                                <Twitter />
                            </SocialIconLink>
                        </FooterSocialWrap>
	    		<FooterContact>
	                    <TitleContact>email contact: </TitleContact>
	                    <DescContact>marketing@icefaucet.com</DescContact>
	                </FooterContact>
                    </FooterLogoContainer>
                    {footerDataLang.map((items, index) => (
                        <FooterLinkItems key={index}>
                            <FooterLinkTitle>{items.title}</FooterLinkTitle>
                            {
                                items.subLinks.map((subLinks, index) => (
                                    <FooterLink
                                        key={index}
                                        to={
                                            location.pathname === '/' || location.pathname === '/advertise' || location.pathname === '/make-money' || location.pathname === '/statistics'
                                                ? `/${subLinks.link}`
                                                : location.pathname === `/ref/${code}` || location.pathname === `/ref/${code}/advertise` || location.pathname === `/ref/${code}/make-money` || location.pathname === `/ref/${code}/statistics`
                                                    ? `/info/ref/${code}/${subLinks.link}`
                                                    : '#'
                                        }
                                    >
                                        {subLinks.title}
                                    </FooterLink>
                                ))
                            }
                        </FooterLinkItems>
                    ))
                    }
                </FooterNav>
                <WebsiteRightsWrap>
                    <WebsiteRights>&copy; IceFaucet 2022-{new Date().getFullYear()} | {footerRightsDataLang.text}</WebsiteRights>
                </WebsiteRightsWrap>
            </FooterWrap>
        </FooterContainer>
    )
}
export default Footer;
