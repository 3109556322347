import React, { useEffect, useState } from 'react';
import SLView from '../components/SLView';
import LoadingContest from '../components/Contest/LoadingContest';
import axiosInstance from '../axios';
import { SLContainer, SLWrap, SLWrapper } from '../components/SLView/SLViewElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { LotteryHeaderWrap, LotteryInfo, LotteryTitle } from '../components/Lottery/LotteryElements';
import { FilterContainer, FilterWraper, ButtonWrap, ButtonFilter } from '../components/OfferUserStatus/OfferUserStatusElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import SLUserStatus from '../components/SLUserStatus';
import { slFilters } from '../data/slUserStatusData';

const SLPage = () => {
    const [info, setInfo] = useState();
    const [level, setLevel] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [clicked, setClicked] = useState(false);
    const [cryptoCoinsAdInfo, setCryptoCoinsAdInfo] = useState();
    const [surfInfo, setSurfInfo] = useState();
    const [bitTrafficInfo, setBitTrafficInfo] = useState();
    const [filterName, setFilterName] = useState(Object.freeze(localStorage.getItem('sl-filter') ? localStorage.getItem('sl-filter') : 'all'));
    const [filterIndex, setFilterIndex] = useState(localStorage.getItem('sl-filter-index') ? parseInt(localStorage.getItem('sl-filter-index')) : 0);
    const handleFilterClick = (e, index) => {
        const NAME = e.target.getAttribute('value');
        if (NAME && filterName !== NAME) {
	    if (NAME !== 'all') {
		localStorage.setItem('sl-filter', `${NAME}`);
		localStorage.setItem('sl-filter-index', `${index}`);
	    } else {
		localStorage.removeItem('sl-filter');
		localStorage.removeItem('sl-filter-index');
	    };
	    setFilterName(NAME);
	    setFilterIndex(index);
	};
    };
    useEffect(() => {
	const fetchShortLinkView = async () => {
	    filterName !== 'all' && setLoading(true);
	    try {
		const { data } = await axiosInstance.post('shortlink/view/', { filter: filterName });
		setInfo(data.info);
		setLevel(data.level);
		fetchCryptoCoinsAdBanner();
		fetchSurfBanner(data.device);
		//fetchBitTraffic();
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchShortLinkView();
    }, [filterName]);
    const fetchCryptoCoinsAdBanner = async (device) => {
	const { data } = await axiosInstance.post('aadsbanner/cryptocoinsad/', { 'height_list': [250] });
	setCryptoCoinsAdInfo(data.info);
    };
    const fetchSurfBanner = async (device) => {
	const height_list = device === 'desktop' ? [90] : [250, 400];
	const { data } = await axiosInstance.post('aadsbanner/surfads/', { 'height_list': height_list });
	setSurfInfo(data.info);
	if (Object.keys(data.info).length > 0) {
	    const ins = document.getElementById('surf1');
	    ins.className = 'surfe-be';
	    ins.setAttribute('data-sid', data.info.data);
	    const script = document.createElement('script');
	    script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	    document.body.appendChild(script);
	    return new Promise(() => {
		script.remove();
	    });
	};
    };
    const fetchBitTraffic = async () => {
	const { data } = await axiosInstance.post('aadsbanner/bittraffic/', { 'height_list': [250] });
	setBitTrafficInfo(data.info);
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>number</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.number}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total primecoins</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.total}</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			    	Level your shortlink status up to get rewards and earn more.<br />
			        Please do not complete more than one shortlink at the same time to avoid the "invalid token" error.<br />
			        Boost your earning by upgrading your account.<br />
			    	Please often clean the cache and browsing data to keep your iceFaucet account safe and updated. <br />
			        If you have a problem completing a shortlink, just refresh the page and open another shortlink.<br />
			    	To complete most of the shortlinks, you must disable your ad blocker for them.<br />
			    	Cheating for completing shortlinks leads to terminating your account.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer >
	    }
	    {!loading && info !== undefined && !apiError &&
		<SLUserStatus />
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && info !== undefined && (Object.keys(info.info).length > 0 || Object.keys(info.info).length === 0) && !apiError &&
		<SLContainer>
		    <SLWrap>
		    	<LotteryHeaderWrap>
			    <LotteryInfo>
			    	<LotteryTitle style={{ display: 'flex', justifyContent: 'center', borderRadius: '15px' }}>
			            <h2>shortlink filter</h2>
			    	</LotteryTitle>
			        <FilterContainer>
			            <p>Filter shortlinks to show:</p>
			            <FilterWraper>
			            	{slFilters.map((i, index) => (
					    <ButtonWrap key={index}>
					    	<ButtonFilter value={i} onClick={(e) => handleFilterClick(e, index)} itemActive={filterIndex === index ? 1 : 0}>{i}</ButtonFilter>
					    </ButtonWrap>
				    	))}
		                    </FilterWraper>
			        </FilterContainer>
			    </LotteryInfo>
		        </LotteryHeaderWrap>
		    	<SLWrapper>
			    {info.info.map((sl, index) => (
			    	<SLView key={index} sl={sl} setClicked={setClicked} clicked={clicked} id={index} cryptoCoinsAdInfo={cryptoCoinsAdInfo} bitTrafficInfo={bitTrafficInfo} userLevel={level}/>
			    ))}
		        </SLWrapper>
		    </SLWrap>
		</SLContainer>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length === 0 && !apiError && (filterName === 'favorite' || filterName === '+x%') &&
		<SLContainer>
		    <SLWrap>
			<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
			    {filterName === 'favorite' ?
				<MsgText primary={0}>
				    Like your favorite shortlinks and filter them to access them easily.
				</MsgText> :
				<MsgText primary={0}>
				    Currently, there is no increase in revenue for any shortlink.
				</MsgText>
			    }
		        </MsgWrap>
		    </SLWrap>
		</SLContainer>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length === 0 && !apiError && (filterName === 'all' || filterName === 'easy' || filterName === 'normal' || filterName === 'hard') &&
		<SLContainer>
		    <SLWrap>
			<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
			    <MsgText primary={0}>
			        Currently, there is no {filterName === 'all' ? '' : filterName} shortlink!
			    </MsgText>
			</MsgWrap>
		    </SLWrap>
		</SLContainer>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length === 0 && !apiError && filterName === 'assistance' &&
		<SLContainer>
		    <SLWrap>
			<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
			    <MsgText primary={0}>
			        Currently, There is no assistance for any shortlinks!
			    </MsgText>
			</MsgWrap>
		    </SLWrap>
		</SLContainer>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length > 0 && !apiError && surfInfo !== undefined && Object.keys(surfInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
	    {!loading && info !== undefined && !apiError &&
		<LoadingContest big={1} table={true} />
	    }
	</>
    )
}
export default SLPage;
